<template>

        <Icon  class="iconify-icon explore_icon big_icon text-pink" :icon="item?.icon"/>
        <p class="m-0 ">{{ item?.head }}</p>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
  },
  data() {
      return {
        innerprofile:"/user/home",
      };
    },
    created() {
      AOS.init()  
    },
  };
  </script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped>
    .big_icon{
        font-size: 2.5rem;
        color: red !important;
    }
    p {
        color: #251718;
        padding-top: .5rem;
        font-size: .8rem;
    }
</style>