<template>
     <expansion-panel>
        <label for="panel9" class="expansion-panel-header"><h6 class="fs-title">Preferred Partner</h6></label>
            <div class="expansion-panel-content">
                <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">
                        <div class="row">
                            <div class="col-6">
                                <label class="fieldlabels">Height From: &nbsp;<span > {{ form.height_from }}cm</span></label>
                                <div class="d-flex">
                                    <input v-model="form.height_from" type="range" min="100" max="210"  class="slider1" id="agefrom">
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="fieldlabels">Height To: &nbsp;<span >{{ form.height_to }}cm</span></label>
                                <div class="d-flex">
                                    <input v-model="form.height_to" type="range" min="100" max="210"   class="slider1" id="ageto">
                                </div>
                                <span v-if="v$.form.height_to.$error" class="error"> {{ v$.form.height_to.$errors[0].$message }} <br></span>
                            </div>
                            <div class="col-6">
                                <label class="fieldlabels">Age From: &nbsp;<span > {{ form.age_from }}</span></label>
                                <div class="d-flex">
                                    <input v-model="form.age_from" type="range" min="18" max="60"  class="slider1" id="agefrom">
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="fieldlabels">Age To: &nbsp;<span >{{ form.age_to }}</span></label>
                                <div class="d-flex">
                                    <input v-model="form.age_to" type="range" min="18" max="60"   class="slider1" id="ageto">
                                </div>
                                <span v-if="v$.form.age_to.$error" class="error"> {{ v$.form.age_to.$errors[0].$message }} <br></span>
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Oualification:</label>
                                <Multiselect v-model="form.qualification" :options="qualification" :multiple="true" placeholder="Select Oualification"  />
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Profession :</label>
                                    <Multiselect v-model="form.profession" :options="profession" :multiple="true" placeholder="Select Profession"  />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Religion:</label>
                                <Multiselect v-model="form.religion" :options="religions":multiple="true" placeholder="Select Religion"  @select="prefCastAPI" @remove="prefCastAPI" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Caste:</label>
                                <Multiselect
                                    v-model="form.caste"
                                    :options="cast"
                                    id="ajax"
                                    :multiple="true"
                                    :close-on-select="false" :clear-on-select="false"
                                    :internal-search="false"
                                    :preselect-first="true"
                                    placeholder="Select Caste"
                                    :key="cast"
                                />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Country:</label>
                                <select @change="stateAPI" v-model="form.country" class="form-select" aria-label="Default select example">
                                    <option value="">Select Country</option>
                                    <option v-for="item in country" :value="item?.title">{{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">State:</label>
                                <select @change="districtAPI" v-model="form.state" class="form-select" aria-label="Default select example">
                                    <option value="">Select state</option>
                                    <option v-for="item in states" :value="item?.title">{{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">District:</label>
                                <select v-model="form.district" class="form-select" aria-label="Default select example">
                                    <option value="">Select district</option>
                                    <option v-for="item in district" :value="item?.title">{{ item?.title }}</option>
                                </select>
                            </div>
                           
                        </div>
                    </div>
                    <input type="submit" name="next" class="next action-button" value="Save" />
                </form>                    
                </fieldset>
            </div>
        </expansion-panel>
        <ErrorToast  :key="error"    :message="error" @update="errorup" />
        <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import Multiselect from 'vue-multiselect'
import { prefReligions,prefCast,qualification,profession,country,states,district} from "@/util/Services/register";
import { minValue } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core'
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { Panel4 } from '@/util/Services/Authenticated/edit';
export default{
    components:{
        Multiselect,ErrorToast,SuccesToast
    },
    data(){
        return{
            v$:useVuelidate(),
            qualification : [],
            profession    : [],
            religions     : [],
            country       : [],
            states        : [],
            cast          : [],
            district      : [],
            p :this.data?.preferred_partner?.profession ? this.data?.preferred_partner?.profession : '',
            form:{
                height_from   : this.data?.preferred_partner?.height_from ? this.data?.preferred_partner?.height_from : 0 ,
                height_to     : this.data?.preferred_partner?.height_to ? this.data?.preferred_partner?.height_to : 0,
                age_from      : this.data?.preferred_partner?.age_from ? this.data?.preferred_partner?.age_from : 0 ,
                age_to        : this.data?.preferred_partner?.age_to  ? this.data?.preferred_partner?.age_to : 0,
                qualification : this.professionvalue(this.data?.preferred_partner?.qualification),
                profession    : this.professionvalue(this.data?.preferred_partner?.profession),
                religion      : this.data?.prefered_religions,
                country       : this.data?.preferred_partner?.country,
                state         : this.data?.preferred_partner?.state,
                caste         : this.data?.prefered_castes,
                district      : this.data?.preferred_partner?.district,
            },
            error   : null,
            success : null,
        }
    },
    validations(){
        return{
                form:{
                    age_to    : {minValue:minValue(this.form.age_from)},
                    height_to : {minValue:minValue(this.form.height_from)}
                } 
         } ;
    },
    props:{
      data:{required:true}
    },
    methods:{
       
        submit(){
            this.v$.$validate()
            if(!this.v$.$error){
                Panel4(this.form).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message})
                setTimeout(() => { this.$emit('submit',true);}, 3000)
            }else{
                document.getElementById("ageto").focus();
            }
        }, 
        professionvalue(professionva){
            if (professionva) {
            try {
                return JSON.parse(professionva);
                }
                catch(err) {
                    return professionva;
                }
            }
         
        },
        async prefReligionsAPI(){
            await prefReligions().then(r=>{this.religions = r?.data?.data});
                this.prefCastAPI();
        },
        prefCastAPI(){
            // this.isLoading = true
            try{
                let array = this.form.religion;
                if (array.length != 0) {
                    prefCast(this.form.religion).then(r=>{this.cast = r?.data?.data});
                    if (!this.cast) {
                    this.cast= [];
                } 
                } else {
                    this.cast= [];
                }
            }catch(err){
                console.log(err)
            }
        },
        async qualificationAPI(){
            let p = [];
            await qualification().then(r=>{p = r?.data?.data,this.qualification = p.map(this.myFunction)});
        },
        async professionAPI(){
            let p = [];
            await profession().then(r=>{p = r?.data?.data,this.profession = p.map(this.myFunction)});
        },
        myFunction(num){
            return num?.title;
        },
        async countryAPI(){
            await country().then(r=>{this.country = r?.data?.data});
            this.stateAPI();
        },
        async stateAPI(){
            if (this.form.country) {
                await states(this.form.country).then(r=>{this.states = r?.data?.data});
            }
            this.districtAPI();
        },
        async districtAPI(){
            if (this.form.state) {
                await district(this.form.state).then(r=>{this.district = r?.data?.data});
            }
        },
        errorup(){
            this.error =null;
            this.success = null;
        },
        
    },
    mounted(){
        this.professionAPI();
        this.qualificationAPI();
        this.prefReligionsAPI();
        this.countryAPI();
    }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>

