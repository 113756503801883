<template>
    <div class="bg-pale">
<div class="container ">
  <div class="row servicebenefit pt-5 flex-wrap align-items-center">
  
    <div class="col-md-6" v-for="(item, index) in data" :key="index">
        <a style="text-decoration: none; color: #2c3e50;" :href="'tel:'+item?.a">
             <BeneCard :item=item />
        </a>
    </div>
  </div>
</div>
</div>
</template>
<script>
   import BeneCard from '@/components/General/BeneCard.vue';
   export default {
      components: {
         BeneCard
      },
      data() {
        return {
            data:[{
                icon:'line-md:phone-call-loop',
                head:'Call Us on',
                p:'+91 9387116659',
                a:'+91 9387116659'
            },{
                icon:'streamline:missed-call',
                head:'Get a Call Back',
                p:'Give a missed call to 94959 70147',
                a:'+91 9495970147'
            },
            // {
            //     icon:'streamline:chat-two-bubbles-oval',
            //     head:'Live chat',
            //     p:''
            // }
        ]
        }
    },
      
   }
   </script>
   