<template>
  <!-- Navigation bar for  -->
  <Nav :navactive="navactive"  />
  <!-- Main Sider in Home -->

  <div class="site-section bg-white position-relative" style="z-index: 0">
    <div class="container">
      <Couple :succes_story="succes_story" :base_url="base_url"/>
    </div>
  </div>
  <Footer />
</template>
<script>
// @ is an alias to /src
import Nav from "@/components/General/Navbar.vue";
import Couple from "@/components/Success_Story/Couple.vue";
import Footer from "@/components/General/Footer.vue";
import Login from "@/components/General/Login.vue";
import {success_stories} from "@/util/Services/success_story"
import { heightValue } from '@/util/Services/height';
export default {
  name: "HomeView",
  components: {
    Nav,
    Couple,
    Footer,
    Login,
  },
  data() {
    return {
      navactive:"success",
      succes_story: [],
      base_url:''
    };
  },
  methods: {
   async SuccessApi(){ 
        //  let height = heightValue();
         await success_stories().then(r => {this.succes_story = r?.data?.data ,this.base_url = r?.data?.base_url});
      }
  },
  mounted(){
    this.SuccessApi();
  }
  
};
</script>
<style>
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1520px;
  }
}
</style>
