<template>

<main class="site-wrap">

<div class="site-section aos-init bg-white pb-0 position-relative " data-aos="fade">
 


<div class="album py-5 bg-light">
  <div class="container px-3">
    <div class="row">
      <div class="col-12">
        <h4 class="pink-text sta">
          Help and Support for Your Matrimony Adventure
        </h4>
        <p class="sta">
          Embarking on the journey of matrimony is both thrilling and daunting. From the exhilarating highs of newfound love to the inevitable challenges that arise along the way, having the right support and guidance can make all the difference. At Marry Help, we're dedicated to providing help and support every step of the way, ensuring that your matrimony adventure is filled with love, understanding, and growth.
        </p>
        
      </div>
    </div>
  <div class="row">
    
             <!-- //form view -->
              <Form @error="errors" @success="successer" />

  </div>

    


  </div>
  
</div>
</div>
<Pale />

</main>
                 <ErrorToast :key="error"  :message="error" @update="errorup" />
                <SuccesToast :key="success"  :message="success" @update="errorup" />
    </template>
  
   <script > 
   import Form from '@/components/Help/FormView.vue';
   import Pale from '@/components/General/Pale.vue';
   import AOS from 'aos'
   import ErrorToast from "@/components/General/ErrorToast.vue"
   import SuccesToast from "@/components/General/SuccessToast.vue"
   export default {
      components: {
          Form,Pale,ErrorToast,
          SuccesToast
      },
     methods:{
        errorup(){
          this.error =null;
          this.success = null;
        },
        errors(error){
          this.error = error;
        },
        successer(success){
          this.success =success;
        },
     },
     mounted(){
           AOS.init()
     },data(){
      return{
        error:null,
        success:null,
      }
     }
   }
   </script>
   <style>
   .big-icon {
      font-size: 28px;
   }
     @media (min-width: 1600px) {
       .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
           max-width: 1520px;
       }
     }
   
   </style>