<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
        <div class="container-fluid p-0">
        <div class="d-flex flex-wrap">
            <div class="col-lg-12 col-xl-12 col-12 p-0">
                <div class="d-flex flex-wrap ">
                   <MainNav />
                    <div class="col-12 p-0">
                        <main class="p-0 p-lg-2 pt-5 mt-4 mt-lg-0 pt-lg-0 ">
                            <div class="mainbody d-flex flex-wrap ">
                                <SideNav :percentage="parseInt(data?.percentage)" :pending_data="data?.pending_data" :links="false" />
                                <div class="col-xl-9 col-lg-8  col-md-8 col-12">
                                    <div class="mainbody2 registerpage p-3 m-2 pb-5 mb-4 mb-lg-0">
                                        <accordion>
                                            <input type="radio" id="panel6" name="accordion" hidden  value="panel6" v-model="accordion">
                                            <ExpansionPanel1 :key="data" :data="data?.data" @submit="ApiCall" />
                                        </accordion>
                                        <accordion>
                                            <input type="radio" id="panel7" name="accordion" hidden value="panel7" v-model="accordion" />
                                            <ExpansionPanel2 :key="data" :data="data?.data" @submit="ApiCall" />
                                        </accordion>

                                        <accordion>
                                            <input type="radio" id="panel8" name="accordion" hidden value="panel8" v-model="accordion" />
                                            <ExpansionPanel3 :key="data" :data="data?.data"  @submit="ApiCall"/>
                                        </accordion>

                                        <accordion>
                                            <input type="radio" id="panel9" name="accordion" hidden value="panel9" v-model="accordion" />
                                            <ExpansionPanel4 :key="data" :data="data?.data"  @submit="ApiCall" />
                                        </accordion>
                                    </div>

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
        <MobileNav />
    </div>
  </body>
</template>
<script>
import AOS from 'aos'
import { Icon } from "@iconify/vue";
import MainNav from "@/components/Authenticated/UserMainNav.vue";
import SideNav from "@/components/Authenticated/Edit/SideNav.vue";
import MobileNav from  "@/components/Authenticated/MobileNav.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
import { userData } from "@/util/Services/Authenticated/edit"
import ExpansionPanel1 from '@/components/Authenticated/Edit/ExpansionPanel1.vue';
import ExpansionPanel2 from '@/components/Authenticated/Edit/ExpansionPanel2.vue';
import ExpansionPanel3 from '@/components/Authenticated/Edit/ExpansionPanel3.vue';
import ExpansionPanel4 from '@/components/Authenticated/Edit/ExpansionPanel4.vue';
import { ref } from 'vue';
export default {
  name: "UserHomeView",
  props: ['id'],
  setup() {
            const country             = ref(null);
            const profession          = ref(null);
            const qualification       = ref(null);
            const accordion           = 'panel6';
            const data                = ref([]);
            async function ApiCall() {
                await userData().then(r=>{data.value = r?.data});
            }
            return {
                data,accordion,country,profession,qualification,ApiCall
            }
        },
  components: {
    MainNav,
    SideNav,
    Icon,
    MobileNav,
    SuccesToast,
    ExpansionPanel1,
    ExpansionPanel2,
    ExpansionPanel3,
    ExpansionPanel4,
  },
  

  mounted(){
    AOS.init()  
    if (this.id  == 1) {
        this.accordion = "panel9";
    }
  },
  created(){
    this.ApiCall();
  }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
   
    