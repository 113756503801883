<template>
    <fieldset>
      <form @submit.prevent="next">
                  <div class="form-card col-12">
                    <div class="row">
                      <div class="col-12 text-center">
                        <h5 class="fs-title text-center">Enter OTP</h5>
                      </div>
                      <div id="otp" class="col-12  d-flex flex-row justify-content-center mt-2"> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(1)" type="text" id="input1" maxlength="1" /> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(2)" type="text" id="input2" maxlength="1" /> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(3)" type="text" id="input3" maxlength="1" /> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(4)" type="text" id="input4" maxlength="1" /> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(5)" type="text" id="input3" maxlength="1" /> 
                                    <input class="m-2 text-center form-control rounded border "  v-on:keyup="inputenter(6)" type="text" id="input4" maxlength="1" /> 
                                  </div>
                         
                      </div>
                    </div>
                    <div class="d-flex justify-content-center" >
                      <div v-if="loading" style="color:red;" class="spinner-border m-4 " role="status">
                                <span class="sr-only"></span> 
                      </div>
                        <input v-else-if="submit"  type="submit" name="next" class="next action-button" value="Submit" />
                        <input v-else  type="submit" name="next" disabled style="background-color:#708090;" class="next action-button" value="Submit" />
                    </div>
                   
                  </form>
                </fieldset>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { OtpCheck } from '@/util/Services/forgot';
export default{
  props: {
    loading: {
      required: true,
    },
  },
data() {
    return {
                v$:useVuelidate(),
                form:{
                     number:null,  
                     numbers:null,  
                },
               submit:false,
    };
},
validations(){
  return{
      form:{
           
      }             
  };
},
mounted(){
  
},
methods:{
    next(){
          this.v$.$validate()
          if(!this.v$.$error){
            this.$emit('form-data', this.form);
          }
    },

    inputenter(id) {

const inputs = document.querySelectorAll('#otp > *[id]');
console.log({inputs});
this.number = null;
for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('keydown', function(event) {
        if (event.key === "Backspace") {
            inputs[i].value = '';
            if (i !== 0) inputs[i - 1].focus();
        } else {
            if (i === inputs.length - 1 && inputs[i].value !== '') {
                return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
                inputs[i].value = event.key;
                this.form.number = event.key;
              
                if (i !== inputs.length - 1) inputs[i + 1].focus();
                event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
                inputs[i].value = String.fromCharCode(event.keyCode);
                if (i !== inputs.length - 1) inputs[i + 1].focus();
                event.preventDefault();
            }
            
        }
        

    });
}
this.getValue();
console.log({inputs},this.form,'number');

},
getValue(){
  const inputs = document.querySelectorAll('#otp > *[id]');
  let a = [];
  // console.log({inputs});
  this.number = null;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value) {
          a[i] = inputs[i].value;
        }
        
      }
      this.form.number = a;
      this.form.numbers = a.toString().replaceAll(',', '');
      this.submit   = false;
      try {
            if (a.length == 6) {
              this.submit   = true;
            }
        }
        catch(err) {
          console.log({err});
        }
    }
},
}
</script>
<style scoped>

.form-control {
        min-width: 10px;
    }
@media (min-width: 1200px) {
    .form-control {
        min-width: 10px;
    }
}
</style>
