<template>
    <expansion-panel>
       <label for="panel7" class="expansion-panel-header"><h6 class="fs-title">Upload Success Story</h6></label>
           <div class="expansion-panel-content">
               <fieldset>
                   <div class="form-card">
                       <div id="app" class="container my-3">
                           <div class="row">
                                <div class="col-md-12 row">
                                    <h5>Upload Success Story</h5>
                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-group text-start w-100 ">
                                            <label for="my-file">Select Image</label>
                                            <input :key="reload" type="file" v-bind:class="{'err':v$.image.$error}" accept="image/png, image/jpeg, image/jpg" @change="previewImage" class="form-control-file" id="my_file">
                                            <span v-if="v$.image.$error" class="error"> Image is required <br></span>
                                        </div>
                                    </div>
                                    <div v-if="preview" class="col-md-6">
                                                    <v-card   width="auto m-2">
                                                      <div class="example">
                                                        <cropper ref="cropper" class="example-cropper" :src="preview" 	:stencil-props="{
                                                          handlers: {},
                                                          movable: false,
                                                          resizable: false,
                                                          aspectRatio: 53/37,
                                                        }"
                                                        :resize-image="{
                                                          adjustStencil: false
                                                        }"
                                                        image-restriction="stencil" />
                                                        <drop-zone
                                                          v-model="preview"
                                                          :label="upload"
                                                          :location="location"
                                                          
                                                          @uploaded="newImagesUploaded"
                                                          class="pb-15"
                                                        />
                                                        <div class="button-wrapper ">
                                                          <span v-if="!loading" class="button" @click="cropImage"> Upload </span>
                                                          <div v-else class="spinner-border " role="status"></div>
                                                        </div>
                                                      </div>
                                                    </v-card>
                                    </div>
                                    <div  v-if="preview_list.length" class="col-md-6 row">
                                        <div class=" p-2 mt-3 d-flex"  >
                                            <template v-if="preview_list.length" >
                                                <div  class="containerimg m-1">
                                                    <img :src="preview_list[preview_list.length -1]" class="img-fluid  shadow-lg" />
                                                    <button @click="remove(preview_list.length -1)" class="button">X</button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-group text-start w-100 ">
                                            <label for="my-file">Heading</label>
                                            <input v-model="heading"  :key="reload" v-bind:class="{'err':v$.heading.$error}" type="text" class="form-control-file w-100" id="my_file">
                                            <span v-if="v$.heading.$error" class="error"> {{v$.heading.$errors[0].$message.replace('This field','Heading') }} <br></span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 d-flex align-items-center">
                                        <div class="form-group text-start w-100">
                                            <label for="my-file">Details</label>
                                            <textarea  maxlength="200" v-bind:class="{'err':v$.details.$error}" v-model="details" name="" id=""></textarea>
                                            <span v-if="v$.details.$error" class="error">{{v$.details.$errors[0].$message.replace('This field','Details') }} <br></span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 d-flex justify-content-center">
                                        <div v-if="!loading" > 
                                            <input type="submit" @click="submit" name="next" class="next action-button" value="Upload" /> 
                                        </div>
                                        <div v-else class="spinner-border " role="status"></div>
                                    </div>

                                    <h5 v-if="pending.length" class="mt-3">Uploaded Success Story</h5>
                                    <div v-if="pending.length"  class="col-md-12 happycouples row">
                                        <div class="col-sm-6 col-md-6 col-lg-4 my-2 p-1" v-for="(item, index) in pending" :key="index">
                                            <Card :item=item  :base_url="$store.getters.base_url"/>
                                        </div>
                                    </div>

                                    <h5 v-if="approved.length" class="mt-3">Approved Success Story</h5>
                                    <div v-if="approved.length"  class="col-md-12 row">
                                        <div class="col-sm-12 col-md-6 col-lg-4 my-2 p-1" v-for="(item, index) in approved" :key="index">
                                            <Card :item=item  :base_url="$store.getters.base_url"/>
                                        </div>
                                    </div>

                                </div>
                           </div>
                       </div>
                   </div>
               </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast  :key="error"    :message="error"   @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { ref } from 'vue';
import Multiselect from 'vue-multiselect';
import { Cropper } from 'vue-advanced-cropper';
import { useVuelidate } from '@vuelidate/core';
import VueEasyLightbox from 'vue-easy-lightbox';
import { required,minLength } from "@vuelidate/validators";
import Card from '@/components/General/SuccessStoryCard.vue';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { uploadSuccesstoryImage,pendingSuccesstoryImage,approvedSuccesstoryImage } from '@/util/Services/Authenticated/edit';
export default{
    components:{ Multiselect,ErrorToast,SuccesToast,VueEasyLightbox,Card ,Cropper},
    setup() {
        const v$           = useVuelidate();
        const visibleRef   = ref(false);
        const visibleRef1  = ref(false);
        const visibleRef2  = ref(false);
        const remove       = ref(false);
        const reload       = ref(false);
        const loading      = ref(false);
        const error        = ref(null);
        const success      = ref(null);
        const base_url     = ref(null);
        const indexRef     = ref(0); 
        const indexRef1    = ref(0); 
        const indexRef2    = ref(0); 
        const imgsRef      = ref([]);
        const imgsRef1     = ref([]);
        const imgsRef2     = ref([]);
        const pending      = ref([]);
        const approved     = ref([]);
        const rejected     = ref([]);
        const preview_list = ref([]);
        const image_list   = ref([]);
        const preview      = ref(null);
        const image        = ref(null);
        const heading   = ref(null);
        const details   = ref(null);
        
        const onShow = (index) => { 
           
            if (!remove.value)  visibleRef.value =  !visibleRef.value , indexRef.value = index;
        }
        const onShow1 = (index) => { 
           
            if (!remove.value) visibleRef1.value =  !visibleRef1.value , indexRef1.value = index;
        }
        const onShow2 = (index) => { 
            
            if (!remove.value) visibleRef2.value =  !visibleRef2.value , indexRef2.value = index;
        }
        const removeCheck = () => { 
            remove.value = !remove.value ;
            
        }
        const hide = () => { visibleRef.value =  false , visibleRef1.value =  false , visibleRef2.value =  false; }
    
        async function getPending(){
            let Arrays  = [];
            let Arrays1 = [];
            let Arrays2 = [];
                await pendingSuccesstoryImage().then(r=>{Arrays = r?.data?.data ? r?.data?.data : [],pending.value = r?.data?.data ? r?.data?.data : [],r?.data?.base_url ? base_url.value = r?.data?.base_url : null});
                imgsRef.value  = Arrays.map(myFunction);
                await approvedSuccesstoryImage().then(r=>{Arrays1 = r?.data?.data ? r?.data?.data : [],approved.value = r?.data?.data ? r?.data?.data : [],r?.data?.base_url ? base_url.value = r?.data?.base_url : null});
                imgsRef1.value = Arrays1.map(myFunction);
                

                function myFunction(num) {
                    return base_url.value+num?.fam_image;
                }
        }
        return {
            visibleRef,indexRef,imgsRef,visibleRef1,indexRef1,imgsRef1,approved,visibleRef2,indexRef2,imgsRef2,rejected,
            base_url,pending,preview_list,image_list,reload,loading,error,success,heading,details,v$,preview,image,
            hide,getPending,onShow,onShow1,onShow2,removeCheck
        }
   },
   validations(){
        return{
               
                    heading        : {required,minLength:minLength(10)},
                    details        : {required,minLength:minLength(50)},
                    image          : {required},
                
         } ;
    },
   props:{ data:{required:true} },
   methods:{
            // previewMultiImage: function(event) {
            //     var input = event.target;
            //     var count = input.files.length;
            //     var index = 0;
            //     // console.log(this.count);
            //     if (input.files) {
            //         while(count --) {
            //             var reader = new FileReader();
            //             const arr = ['jpeg','png','jpg'];
            //             const substr = input.files[index].type;
            //             const substr1 = substr.split('/')
            //             const subArr = arr.some(str => str.includes(substr1[1]));
                      
            //             if (subArr) {
            //                 reader.onload = (e) => {
            //                     this.preview_list.push(e.target.result);
            //                 }
            //                 this.image_list = input.files[index];
            //                 reader.readAsDataURL(input.files[index]);
            //                 // index ++;
            //             } else {
            //                 this.$swal.fire({
            //                                     icon: "error",
            //                                     title: "Oops...",
            //                                     text: "Only upload JPG, JPEG, or PNG files.!",
            //                                 });
            //             }
            //         }
            //     }
            //     this.reload = !this.reload;
            // },
            previewImage: function(event) {
            var input = event.target;
            if (input.files) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            }
            this.image=input.files[0];
            reader.readAsDataURL(input.files[0]);
            }
    },
            remove(index){
                    this.preview_list.splice(index, 1);
                    this.image_list = null;
                    this.reload = !this.reload;
            },
            async submit(){
                this.v$.$validate()
                if(!this.v$.$error){
                    this.loading = true;
                    // console.log(this.image_list,'image_list');
                    const result = this.$refs.cropper.getResult();
                    await uploadSuccesstoryImage(this.dataURLtoFile(result.canvas.toDataURL(
            "image/jpeg"
          )),this.heading,this.details).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false,this.getPending()});
                    if (this.success) {
                        this.preview_list = [];
                        this.image_list = null;
                        this.heading = null;
                        this.details = null;
                        this.image   = null;
                        this.preview = null;
                        this.reload = !this.reload;
                        this.v$.$reset();
                    }
                }
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            },
            
            async removePending(id){
              
                this.removeCheck();
                this.$swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "red",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, delete it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeFamilyImage(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.getPending()});
                                    }
                                    this.removeCheck();
                                });
                                
            }
            
 },
 created(){
    this.getPending();
 }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
.preview{
    height: 109px !important;
    width:  128.5px !important;
}
img{
   height: 109px !important;
   width: 128.5px !important;
   object-fit: cover;
   object-position: 10% 15%;
}

.containerimg {
  position: relative;
  height: 109px !important;
  width: 128.5px !important;
}

.containerimg img {
    height: 109px !important;
    width: 128.5px !important;
    object-fit: cover;
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.spinner-border{
    color: #af0b0b;
}

</style>
