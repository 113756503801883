<template>
    <footer class="site-footer pb-2 position-relative" style="z-index: 0;">
    <div class="container">
      <div class="row mr-4">
        <div class="col-md-4 mb-4 flex-row">
          <img src="@/assets/img/logowithr.png" alt="" class="img-fluid mb-4"> <br>
          <a :href="facebook" class="pl-0 pr-3"><Icon class="big-icon" icon="eva:facebook-fill"/></a>
          <a :href="x" class="pl-3 pr-3 m-3"><Icon class="big-icon" icon="mage:x"/></a>
          <a :href="youtube" class="pl-3 pr-3 m-3"><Icon class="big-icon" icon="formkit:youtube"/></a>
          <a :href="insta" class="pl-3 pr-3 m-3 "><Icon class="big-icon" icon="lets-icons:insta"/></a>
          <a :href="linkedin" class="pl-3 pr-3 m-3 "><Icon class="big-icon" icon="ri:linkedin-fill"/></a>

          <iframe class="mt-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.9536277143993!2d76.95223847501272!3d8.503883691538006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bb2bcb6e9ec7%3A0x647494cb808b72a4!2smarryhelp.in!5e0!3m2!1sen!2sin!4v1728107519258!5m2!1sen!2sin" width="250" height="150"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="col-md-8 ">
          <div class="row footer-inner">
            <div class="col-sm-6 col-md-4 mb-4 text-left">
              <h2 class="footer-heading mb-4 text-left" >About</h2>
              <ul class="list-unstyled align">
                <li><a :href="about_us">About</a></li>
                <li><a :href="message">Message</a></li>
                <!-- <li><a :href="newsBlogs">News & Blogs</a></li> -->
                <li><a :href="help">Help & Supports</a></li>

              </ul>
            </div>
            <div class=" col-sm-6 col-md-4 mb-4">
              <h2 class="footer-heading mb-4">Company</h2>
              <ul class="list-unstyled align">
                <!-- <li><a :href="howWeWork">How we work</a></li>
                <li><a :href="termsOfService">Terms of service</a></li> -->
                <li><a :href="pricing">Pricing</a></li>
                <li><a :href="faq">FAQ</a></li>
              </ul>
            </div>
            <div class="col-md-4 mb-4">
              <h2 class="footer-heading mb-4">Contact Us</h2>
              <p class="text-white align">NRA 136, Opp.Muthoot Capital View Flat,
Nandavanam Samadhimadam Road, Palayam,
Thiruvananthapuram - 695 033</p>
                <ul class="list-unstyled align">
                  <li><a href="#">
                    +91 9387116659,94959 70147</a></li>
                  <li><a href="mailto:marryhelp.in@gmail.com">marryhelp.in@gmail.com</a></li>
                  <li><a href="https://www.marryhelp.in/">www.marryhelp.in</a></li>
                </ul>
              
            </div>
          </div>
        </div>
        <div class="col-12 text-center text-white mt-4">
         © Copyright  marryhelp.in All Rights Reserved Designed with <Icon icon="ph:heart-fill"/> <a target="_blank" :href="comapny" class="text-white">Kenland IT Solutions</a>
        </div>

      </div>
    
    </div>
    <hr class="border-white mt-4">
    <div class="container">
      <ul class="list-unstyled d-flex flex-wrap justify-content-between">
        <li>
          <a :href="privacyPolicy">Privacy Policy</a>
        </li>
        <li>
          <a :href="Careers">Careers</a>
        </li>
        <li>
          <a :href="wedding">Wedding planning</a>
        </li>
        <li>
          <a :href="Advertise">Advertise</a>
        </li>
        <li>
          <a :href="Terms">Terms & Conditions</a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script > 

import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
   components: {
       Icon
   },
   data() {
    return {
      image:"",
      facebook:"/home",
      x:"/home",
      youtube:"/home",
      insta:"/home",
      linkedin:"/home",
      about_us:"/about_us",
      message:"/message",
      features:"/",
      newsBlogs:"/",
      help:"/help",
      howWeWork:"/message",
      termsOfService:"/",
      pricing:"/Pricing",
      faq:"/FAQ",
      comapny:"https://www.kenland.in/",
      privacyPolicy:"/Privacy",
      Careers:"/Career",
      wedding:"/Wedding",
      Advertise:"/Advertise",
      Terms:"/Terms&Conditions",
    };
  },
  mounted(){
        AOS.init()
  }
}
</script>
<style scoped>
.big-icon {
   font-size: 21px;
}
.footer-heading{
  text-align: start !important;
} 
.align{
  text-align: start !important;
}
iframe {
    border-radius: 15px;
}
@media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1520px;
    }
}
</style>