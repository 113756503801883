import axios from 'axios'
import moment from "moment";
let url = process.env.VUE_APP__BASE_URL;

export const country = () => {
    return axios.post(url+"country").then((response) => response).catch(error => console.log(error));
};
export const states = (country) => {
    return axios.post(url+"state",{country:country}).then((response) => response).catch(error => console.log(error));
  };
export const district = (state) => {
    return axios.post(url+"district",{state:state}).then((response) => response).catch(error => console.log(error));
  };
export const mailcheck = (phone,password) => {
    return axios.post(url+"password-existence",{phone:phone,password:password}).then((response) => true).catch((response) => false);
  };
export const QuickRegistration = (form) => {
    return axios.post(url+"quick-customer-registration",
        {
            fullname       : form?.name,
            gender         : form?.gender,
            email          : form?.emails,
            dob            : moment(form?.date).format("YYYY-MM-DD"),
            state          : form?.state,
            country        : form?.country,
            district       : form?.district,
            phone          : form?.phone,
            password       : form?.password,
            marital_status : form?.marital_status,
            height         : form?.height,
            post           : form?.post,
            term           : form?.check,
            pin            : form?.post_code
        }).then((response) => response).catch((error) => error);
  };  
export const profileCreatedBy = () => {
    return axios.post(url+"profile-created").then((response) => response).catch(error => console.log(error));
};
export const getMotherTongue = () => {
  return axios.post(url+"get-mother-tongue").then((response) => response).catch(error => console.log(error));
};
export const complexion = () => {
  return axios.post(url+"get-complexion").then((response) => response).catch(error => console.log(error));
};
export const bodyType = () => {
  return axios.post(url+"get-body-type").then((response) => response).catch(error => console.log(error));
};
export const bodyStatus = () => {
  return axios.post(url+"get-body-status").then((response) => response).catch(error => console.log(error));
};
export const BloodGroup = () => {
  return axios.post(url+"get-blood-group").then((response) => response).catch(error => console.log(error));
};
export const qualification = () => {
  return axios.post(url+"get-qualification").then((response) => response).catch(error => console.log(error));
};
export const profession = () => {
  return axios.post(url+"get-profession").then((response) => response).catch(error => console.log(error));
};
export const income = () => {
  return axios.post(url+"get-income").then((response) => response).catch(error => console.log(error));
};
export const prefReligions = () => {
  return axios.post(url+"get-pref-religions").then((response) => response).catch(error => console.log(error));
};
export const prefCast = (religions) => {
  return axios.post(url+"get-pref-cast",{religion:religions}).then((response) => response).catch(error => console.log(error));
};
export const Registration = (form,form1,form2) => {
  return axios.post(url+"complete-user-profile",
      {
          // first form
          fullname       : form?.name,
          gender         : form?.gender,
          email          : form?.emails,
          dob            : moment(form?.date).format("YYYY-MM-DD"),
          state          : form?.state,
          country        : form?.country,
          district       : form?.district,
          phone          : form?.phone,
          password       : form?.password,
          marital_status : form?.marital_status,
          religion       : form?.religion,
          cast           : form?.cast,
          mother_tongue  : form?.mother_tongue,
          post           : form?.post,
          pin            : form?.postal_code,
          whatsappnumber : form?.whatsapp,
          created        : form?.created_by,

          //Second Form
          //Personal Information
          height                : form1?.height,
          weight                : form1?.weight,
          complexion            : form1?.complexion,
          body_type             : form1?.body_type,
          body_status           : form1?.body_status, 
          qualification         : form1?.qualification,
          qualification_details : form1?.qualification_details,
          profession            : form1?.occupation,
          profession_details    : form1?.occupation_details,
          workplace             : form1?.work_location,
          income                : form1?.annual_income,
          diet                  : form1?.diet_preference,
          abrode_check          : form1?.overseas,

          //Family Details
          father_name            : form1?.father_name,
          father_occupation      : form1?.father_occupation,
          mother_name            : form1?.mother_name,
          mother_occupation      : form1?.mother_occupation,
          no_brother             : form1?.brother,
          married_no_brother     : form1?.married_no_brother,
          no_sisters             : form1?.sister,
          married_no_sisters     : form1?.married_no_sisters,
          family_about           : form1?.about, 
          other_details_brother  : form1?.other_details_brother ? form1?.other_details_brother : "",
          other_details_sister   : form1?.other_details_sister ? form1?.other_details_sister : "",
          house_name             : form1?.house_name ? form1?.house_name : "",

          //3rd Form
          pref_age_from          : form2?.agefrom,
          pref_age_to            : form2?.ageto,
          pref_height_from       : form2?.heightfrom,
          pref_height_to         : form2?.heightto,
          pref_qualification     : form2?.qualification ? JSON.stringify(form2?.qualification) : JSON.stringify([]),
          pref_profession        : form2?.profession ? JSON.stringify(form2?.profession) : JSON.stringify([]),
          pref_partner_religions : form2?.religion,
          pref_partner_caste     : form2?.caste,
          pref_country           : form2?.country,
          pref_state             : form2?.state,
          term                   : form2?.check,

      }).then((response) => response).catch((error) => error);
}; 
export const getStar = () => {
  return axios.post(url+"get-star").then((response) => response).catch(error => console.log(error));
}; 
  