export const heightValue = () => {
   let value = 130;
   let option = "";
   let data = [];
    do {
        data.push(cmToFtIn(value));
        value++;
    }
    while (value < 201);
  
   return data;
  };
  export const heightCheck = (value) => {
    return cmToFtIn(value);
   };
  function cmToFtIn(cm) {
    // Convert cm to inches
    const inches = cm / 2.54;
    
    // Calculate feet and remaining inches
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    
    return `${cm}cm ${feet}ft ${remainingInches}in`;
}