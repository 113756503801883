<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-12 col-xl-12  col-12 p-0 pt-xl-0 pt-md-5  pt-sm-5">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">

                                <ul class="nav nav-tabs" id="myTab" role="tablist">

                                    <li class="nav-item" role="presentation">
                                        <button  class="nav-link " :class="{'active ':tab == 1}" id="add_request-tab" data-bs-toggle="tab"
                                            data-bs-target="#add_request" type="button" role="tab" aria-controls="add_request"
                                            aria-selected="true">Add Request</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="Approved" class="nav-link" :class="{'active ':tab == 2}" id="approve_request-tab" data-bs-toggle="tab"
                                            data-bs-target="#approve_request" type="button" role="tab" aria-controls="approve_request"
                                            aria-selected="false">Approved Request({{ data.length }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="Pendings" class="nav-link" :class="{'active ':tab == 3}" id="pending_request-tab" data-bs-toggle="tab"
                                            data-bs-target="#pending_request" type="button" role="tab" aria-controls="pending_request"
                                            aria-selected="false">Pending Request({{ data1.length }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="Recevieds" class="nav-link" :class="{'active ':tab == 4}" id="received_request-tab" data-bs-toggle="tab"
                                            data-bs-target="#received_request" type="button" role="tab" aria-controls="received_request"
                                            aria-selected="false">Received Request({{ data2.length }})</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="Feedbackdata" class="nav-link" :class="{'active ':tab == 5}" id="feedback_request-tab" data-bs-toggle="tab"
                                            data-bs-target="#feedback_request" type="button" role="tab" aria-controls="feedback_request"
                                            aria-selected="false">Feedback Request({{ link3?.total }})</button>
                                    </li>

                                </ul>
                                <div class="p-2">

                                    <div class="tab-content" id="myTabContent">

                                        <!-- Add Request -->
                                        <div class="tab-pane fade" :class="{'active show':tab == 1}" id="add_request" role="tabpanel" aria-labelledby="add_request-tab">
                                          <div class="mainbody2 d-flex flex-wrap p-3 p-lg-5 p-3 pt-5 mt-5 pt-lg-5 mt-lg-0 p-lg-5 searchpart">
                                            <div class="container-fluid">
                                                <div class="row justify-content-start">
                                                    <AddRequest @submit="AdvanceSearchAPI" />
                                                </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- approved request -->
                                        <div class="tab-pane fade" :class="{'active show':tab == 2}" id="approve_request" role="tabpanel" aria-labelledby="approve_request-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                              <ApprovedRequest :key="data" :data="data" :link="link" />
                                            </div>
                                        </div>
                                         <!-- pending request -->
                                         <div class="tab-pane fade" id="pending_request" :class="{'active show':tab == 3}" role="tabpanel" aria-labelledby="pending_request-tab">
                                          <Spinner v-if="loading" />
                                            <div v-else  class="row m-4 table">
                                                 <PendingRequest :key="data1" :data="data1" :link="link1" @reload="Pendings()"/>
                                            </div>
                                            
                                        </div>
                                        <!-- received request -->
                                        <div class="tab-pane fade" id="received_request" :class="{'active show':tab == 4}" role="tabpanel" aria-labelledby="received_request-tab">
                                          <Spinner v-if="loading" />
                                            <div v-else  class="row m-4 table">
                                                 <ReceivedRequest :key="data2" :data="data2" :link="link2" @reload="Recevieds()"/>
                                            </div>
                                            
                                        </div>
                                        <div class="tab-pane fade" id="feedback_request" :class="{'active show':tab == 5}" role="tabpanel" aria-labelledby="feedback_request-tab">
                                          <Spinner v-if="loading" />
                                            <div v-else  class="row m-4 table">
                                                 <FeedbackRequest :key="data3" :data="data3" :link="link3" @reload="Feedbackdata()"/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                  </div>
              </div>

          </div>
          <MobileNav :active="active"/>
          <ErrorToast :key="error"  :message="error" @update="errorup" />
  </body>
    </template>
  
   
    <script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue";
    import Spinner from "@/components/Authenticated/Spinner.vue";
    import AddRequest from '@/components/Authenticated/Request/AddRequest.vue';
    import PendingRequest from '@/components/Authenticated/Request/PendingRequest.vue';
    import ApprovedRequest from '@/components/Authenticated/Request/ApprovedRequest.vue';
    import ReceivedRequest from "@/components/Authenticated/Request/ReceivedRequest.vue"
    import FeedbackRequest from "@/components/Authenticated/Request/FeedbackRequest.vue"
    import { ref } from 'vue';
    import { getPendingRequest,getApprovedRequest, Recevied ,Feedback } from '@/util/Services/Authenticated/request';
    import ErrorToast from "@/components/General/ErrorToast.vue";
  
    export default {
      name: "RequestView",
      props: ['id'],
      setup() {
         
            const loading             = ref(false);
            const show                = ref(false);
            const active              = 'RequestView';
            const tab                 = 1;
            const data                = ref([]);
            const data1               = ref([]);
            const data2               = ref([]);
            const data3               = ref([]);
            const link                = ref([]);
            const link1               = ref([]);
            const link2               = ref([]);
            const link3               = ref([]);
            const pending             = ref(true);
            const error               = ref(null);
                         
            return {
                loading,show,active,tab,pending,data,link,error,data1,data2,link1,link2
            }
        },
      components: {
        MainNav,SideNav,Icon,MobileNav,Spinner,AddRequest,PendingRequest,ApprovedRequest,ErrorToast,ReceivedRequest,FeedbackRequest
      },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        async Pendings(){
          this.loading = true;
          await getPendingRequest().then(r=>{this.link1 = r?.data?.data,this.data1 = r?.data?.data?.data});
            this.loading = false;
        },
        async Approved(){
          this.loading = true;
          await getApprovedRequest().then(r=>{this.link = r?.data?.data,this.data = r?.data?.data?.data});
            this.loading = false;
        },
        async Recevieds(){
          this.loading = true;
          await Recevied().then(r=>{this.link2 = r?.data?.data,this.data2 = r?.data?.data?.data});
            this.loading = false;
        },
        async Feedbackdata(){
          this.loading = true;
          await Feedback().then(r=>{this.link3 = r?.data?.data,this.data3 = r?.data?.data?.data});
            this.loading = false;
        },

      },
      mounted() {
        AOS.init()  
        if (this.id  == 1) {
          this.error = "Your current subscription limit has been reached. To continue enjoying our services, please request additional view count "
        }
      },
      created(){
        if (this.id  > 0) {
          this.tab = this.id;
        }
        this.Pendings();
        this.Approved();
        this.Recevieds();
        this.Feedbackdata();
      }
     
    };
    </script>
    <style scoped src="@/assets/css/application.css" />
    <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
    <style scoped src="@/assets/css/components/Authenticated/spinner.css"/>
  <style scoped >
   .select-wrap .icon {
    position: absolute;
    right: 0px;
    top:0%;
    -webkit-transform: translateY(50%) translateX(-15px);
    -ms-transform: translateY(50%) translateX(-15px);
    transform: translateY(50%) translateX(-15px);
    transition: .3s;
    }
    .form-control{
      
        font-size: .7rem !important;
        padding: .175rem !important;
    }
    input{
        font-size: .7rem !important;
    }
    .mainbody2 {
     overflow-x:visible !important;
    }
  </style>
   
    