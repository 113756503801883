
export default {
    state: {
      token              : null,
      base_url           : '',
      user               : [],
      gender             : '',
      isLoggedIn         : false,
      errorMessage       : null,
      successMessage     : null,
      remaining_counting : null,
      religion           : null,
      height             : null,
      dob                : null,
    },
    getters: {
      isAuthenticated    : state => state.isLoggedIn,
      token              : state => state.token,
      user               : state => state.user,
      gender             : state => state.gender,
      dob                : state => state.dob,
      religion           : state => state.religion,
      height             : state => state.height,
      base_url           : state => state.base_url,
      remaining_counting : state => state.remaining_counting,
      header(state){
        const headers = {
            'Authorization': 'Bearer '+state.token,
            'Accept': 'application/json',
            token: state.token
        };
        
        return headers;
      } 
    },
    mutations: {
        token (state,token) {
             state.token = token;
        },
        user (state,user) {
            state.user = user;
            state.gender = user?.user_ids.includes("GRM") ? "Male":'Female';
        },
        user_update (state,user) {
            state.user = user ? user : state.user;
        },
        r_count (state,count) {
            state.remaining_counting = count ? count : 0;
        },
        base_url (state,base_url) {
            state.base_url = base_url ? base_url :state.base_url;
        },
        SET_AUTHENTICATED (state, value) {
            state.isLoggedIn = value
        },
        SUCCESS_MESSAGE(state, value) {
            state.successMessage = value
        },
        Religion(state, value) {
            state.religion = value
        },
        DOB(state, value) {
            state.dob = value
        },
        Height(state, value) {
            state.height = value
        },
    },
    actions: {
        SET_AUTHENTICATED({commit}, value){
            commit('SET_AUTHENTICATED', value)
       },
       SET_LOGIN({commit}, value){
            commit('token', value?.token),
            commit('user', value?.user),
            commit('base_url', value?.base_path)
       },
       UPDATE_LOGIN({commit}, value){
            commit('user_update', value?.data),
            commit('base_url', value?.base_url),
            commit('r_count', value?.data?.r_count),
            commit('Religion', value?.data?.details?.religion),
            commit('DOB', value?.data?.details?.dob),
            commit('Height', value?.data?.details?.height)
        },
       SET_MESSAGE({commit, state}, value){
            state.errorMessage   = value?.response?.data?.message;
            commit('SUCCESS_MESSAGE', value?.data?.message)
       },
    },
    modules: {
    }
  }