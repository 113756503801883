<template>
    <table class="table table-striped mt-4">
        <thead>
            <tr>
                <th scope="col">Request For</th>
                <th scope="col">Requested Date</th>
                <th scope="col">Requested From</th>
                <th scope="col">User Remark</th>
                <th scope="col">Admin Remark</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data">
                <td>{{ item?.master_req?.title }}</td>
                <td>{{ item?.approved_visiting_date ?  item?.approved_visiting_date : ""}}</td>
                <td>
                    <!-- {{ item?.users ?  item?.users?.user_ids+"("+item?.users?.name+")" : ""}} -->
                    <a v-if="item?.users" :href="'/user/'+item?.users?.id" target="_blank" rel="noopener noreferrer">{{ item?.users?.user_ids+"("+item?.users?.name+")" }}</a>
                </td>
                <td>{{ item?.details  }}</td>
                <td>{{ item?.remarks }}</td>
                <td class="redColor" :class="{'text-success':item?.status == 'approved'}">{{ item?.status }}</td>
            </tr>         
        </tbody>
    </table>
    <ErrorToast :key="error"  :message="error" @update="errorup" />
    <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { ref } from 'vue';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
export default {
  name: "RequestView",
  components:{
    ErrorToast,SuccesToast
  },
  props: {
    data: { required: true },
    link: { required: true },
  },
  setup() {
          const error                = ref(null);
          const success              = ref(null);
          function errorup(){
                error.value = null;
                success.value = null;
          }
          return {
           errorup,error,success
          }
      },
}
</script>
<style scoped>
.redColor{
    color: red;
}
</style>
