import axios from 'axios'
import store from '@/store'
let url = process.env.VUE_APP__BASE_URL;

axios.interceptors.request.use(
    (config) => {
        const token = store.getters.token;

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
     
        
      
);
if (store.getters.isAuthenticated) {
    const token = store.getters.token;
    const data = axios.
    create({
        headers: {
            'Authorization': `Bearer ${token}`
        }
     }).
     post(url+"customer/reload-user-details")
    .then(response => response )
    .catch((error) => console.log(error));
    data.then(r=>{
                    store.dispatch('SET_AUTHENTICATED', r?.data ? true:false),
                    store.dispatch('UPDATE_LOGIN', r?.data)
                 });
}
