<template>
    <div class="site-section aos-init bg-white position-relative mt-5" data-aos="fade">

        <div class="container">
                <div class="row flex-wrap sta">
                        <div class="col-lg-12 clients">
                            <h5 class="mb-2 mt-4">Marryhelp</h5>
                            <p>"Matrimonial Service" was established in the year 1998.  It was established under the objective of assisting boys and girls to look for a suitable life partner for them. Having run the bureau for Twenty Two years now, Mr Wencesslaus Lawrence the Director has established  Matrimonial Services  as one among the best Marriage Bureaus in and around Trivandrum, Kerala. The bureau however concentrates mainly on Malayali families. Being a Marriage consultant, he has contacts their clients in India and also abroad countries like United States, Canada, United Kngdom,Australia etc..</p>

                            <p>Located in the heart of Trivandrum City."marryhelp.in" has a good ambience, the office is easily accessible via telephones, e-mails. Mr.Wencesslaus Lawrence personally meets all the clients which helps them to take a judgment of nature and the type of personality each one has for pairing the couple. Informations are  enveloped in the membership form itself to know their social and financial background and everything remains very confidential.</p>

                        </div>
                        <div class="col-12">
                            <img src="@/assets/img/ABOUT.jpg" alt="" class="img-fluid w-100 py-4 lazy">
                        </div>
                    <div class="col-12">
                        <p>"marryhelp.in" aims at uniting the best pairs so that they would have a happy married life, after all marriages are made in heaven, we just unite them. </p>

                        <p>  "marryhelp.in" functions with all new technology and communication system and render services exchanging information between the would be life partners for everlasting bliss later. "marryhelp.in" provides valuable opportunities to understand each other for potential conjugal partnership and their families also. "marryhelp.in" builds unbreakable wedlock between spouses for happy family. </p>
                            
                            <p> "marryhelp.in" delivers services and solutions for matrimony with warmth and spirit to the members you can absolutely rely on the "marryhelp.in" with confidence for perfect match making. Our endeavor is fulfilling the needs of singles in search of prospective spouses. </p>
                            
                            <p>"marryhelp.in" is the Best Malayalam  matrimonial website in maintaining the Kerala wedding directory. Browse all available Brides and Grooms, and Select your life partner at your finger tips. We are having very large database of Malayalam Matrimonial ads.</p>

                            <p>
                                We at "marryhelp.in" are most trusted and credible professionals rendering services insearch of your right life partner by expanding the horizon of opportunities for the singles who are serious in finding most suitable spouse. Our high quality team of professionals aspires for fulfillment of life through perfect match making. Profiles of every member are closely scrutinized and no abuse can take place at all. Your identity and privacy are fully protected from the moment you enter "marryhelp.in". Customer services at "marryhelp.in" are unparallel as it provides data base of potential life partners by screening out irrelevant and inappropriate contents. At "marryhelp.in" data base is constantly updated so that not to waste time in confirming details of prospective brides and grooms.
                            </p>

                            <h6 class="text-danger"><b>We have no other Branches or Agents</b></h6>
                    </div>


                </div>
        </div>
</div>
</template>