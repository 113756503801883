import axios from 'axios'
import header from "@/util/Services/Authenticated/header";

let url = process.env.VUE_APP__BASE_URL;

export const acceptedProposals = (page) => {
    return axios.post(url+"customer/accepted-proposals?page="+page).then((response) => response).catch(error => console.log(error));
  };

export const acceptedProposals_byme = (page) => {
    return axios.post(url+"customer/accepted-proposals-by-me?page="+page).then((response) => response).catch(error => console.log(error));
  };
export const acceptedProposals_other = (page) => {
    return axios.post(url+"customer/me-sended-accepted-proposals?page="+page).then((response) => response).catch(error => console.log(error));
  };