<template>
    <!-- Navigation bar for Home -->
    <Nav :navactive="navactive" :logintrigger="logintrigger" :key="logintrigger" />
    <!-- Main Sider in Home -->
    <SearchForm  @from-submit="submitForm" :formdata="formdata"    :slide="slide" />
    <div class="col-12 main">
        <Cards :carddata="data" :fulldata="Result" @message-updated="login" @more="More" />
    </div>
    <Login :key="show" :show="show" @message-updated="login" />
    <Footer />
    <ErrorToast :key="error"  :message="error"/>
  </template>
  <script>
  import { HomeSearch} from '@/util/Services/home';
  import Nav from "@/components/General/Navbar1.vue";
  import Footer from "@/components/General/Footer.vue";
  import Cards from "@/components/Search Page/Cards.vue";
  import ErrorToast from "@/components/General/ErrorToast.vue";
  import SearchForm from "@/components/Search Page/SearchForm.vue";
  export default {
    name: "SearchView",
    props: {
      form:{ required: true }
    },
    components: {
      Nav,SearchForm,Footer,Cards,ErrorToast
    },
    data() {
      return {
        navactive:"help",
        slide: { gender: ["Female", "Male"] },
        logintrigger:false,
        Result:[],
        error:"",
        show: false,
        formdata:JSON.parse(this.form),
        data:[],
        count:1,
        formsdata :null,
        innerprofile:"/user/",
      };
    },
    methods: {
      async submitForm(form){
        this.formsdata = form;
        await HomeSearch(form,this.count)
        .then(r =>{this.error = r?.response?.data.message,this.Result = r?.data?.data ,this.data =  r?.data?.data?.data});
      },
      async More(){
        this.count++;
          if (this.count > 2) {
              this.logintrigger = true;
          } else {
              await HomeSearch(this.formsdata,this.count)
              .then(r =>{this.error = r?.response?.data.message,this.Result = r?.data?.data ,r?.data?.data?.data ? this.data.push(...r?.data?.data?.data) :[] });
          }
      }
    },mounted() {
      let data = this.form;
      // console.log({data});
    },
  };
  </script>

  