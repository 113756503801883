import axios from 'axios';
import header from "@/util/Services/Authenticated/header";

let url = process.env.VUE_APP__BASE_URL
export const show = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/single_show",{user_id:user_id})
        .then((response) => response).catch((error) => error);
    }
};
export const addShortlist = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/short_list",{to_user:user_id})
        .then((response) => response).catch((error) => error);
    }
};
export const removeShortlist = (user_id) => {
    if (user_id != NaN) {
        return axios.delete(url+"customer/short_list/"+user_id)
        .then((response) => response).catch((error) => error);
    }
};
export const addSavedMatch = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/saved_match",{to_user:user_id})
        .then((response) => response).catch((error) => error);
    }
};
export const removeSavedMatch = (user_id) => {
    if (user_id != NaN) {
        return axios.delete(url+"customer/saved_match/"+user_id)
        .then((response) => response).catch((error) => error);
    }
};
export const addIntrest = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/add-intrest",{to_user:user_id})
        .then((response) => response).catch((error) => error);
    }
};
export const removeIntrest = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/update-interest",{user_id:user_id,status:'cancelled'})
        .then((response) => response).catch((error) => error);
    }
};
export const ApproveIntrese = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/update-interest",{user_id:user_id,status:'approved'})
        .then((response) => response).catch((error) => error);
    }
};
export const RejectIntrese = (user_id) => {
    if (user_id != NaN) {
        return axios.post(url+"customer/update-interest",{user_id:user_id,status:'rejected'})
        .then((response) => response).catch((error) => error);
    }
};