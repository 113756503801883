<template>
    <fieldset>
                  <div class="form-card">
                    <div class="row">
                      <div class="col-7">
                        <h5 class="fs-title">Finish:</h5>
                      </div>
                      <div class="col-5">
                        <h6 class="steps">Step 4 - 4</h6>
                      </div>
                    </div> 
                    <h2 class="purple-text text-center"><strong>Congratulations !</strong></h2>
                    <h5 class="text-center">You are now a member of Marryhelp</h5>
                    <div class="row justify-content-center">
                      <div class="col-12 text-center pt-2">
                        <b>Your Marry Help Profile ID is {{ data.user_ids }}. </b><br>
                        Your Profile id can be viewed on your My Account Page.Login to Your Marryhelp account on your
                        desktop or mobile with your mobile number or registered email ID. You will receive an email from
                        Marryhelp on your registered Email ID- Please Click on the link to <b class="purple-text">Complete the verification</b>
                        process. We wish you good luck in your search for the right partner!
                      </div>
                      <div class="col-7 pt-4"> <img src="@/assets/img/success.png" class="fit-image"> </div>
                    </div> <br><br>
                    <h6 class="purple-text text-center">We wish you good luck in your search for the right partner!</h6>
                    <input @click="next" type="submit" name="upload" class="next action-button" value="Upload Profile pic" />
                    <input @click="home" type="button" name="home" class="previous action-button-previous" value="Home" />
                  </div>
                </fieldset>
</template>
<script>

export default{
    props: {
    data: {
      required: true,
    },
  },
  methods:{
    next(){
            this.$emit('message-updated', 5);
    },
    home(){
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
<style scoped>
.action-button {
  width: 200px !important;
}
</style>