<template>

<div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-12 sta">
              <h4 class="pink-text">
               Couples Wedding Photoshoot & Success Stories
              </h4>
              <p class="sta">
                In a world where love stories unfold in myriad ways, Marry Help stands as a testament to the enduring power of connection. Through its platform, countless individuals have found their soulmates, forging bonds that withstand the test of time. Here are some heartwarming tales of love and matrimony that blossomed through the matchmaking prowess of Marry Help.
              </p>
              <p class="sta">These heartwarming tales from Marry Help serve as a testament to the transformative power of love. Through its platform, individuals from all walks of life have found their happily ever afters, defying the odds and embracing the journey with open hearts. As these couples embark on the adventure of married life, they carry with them the memories of their love stories, forever grateful to Marry Help for bringing them together.</p>
            </div>
          </div>
          <div class="row happycouples">
            <div class="col-sm-6 col-md-6 col-lg-3 my-2" v-for="(item, index) in succes_story"   :key="index">
              <Card :item=item  :base_url="base_url" />
            </div>
          </div
        </div>
      </div>
    </div>
</template>

<script > 
import Card from '@/components/General/SuccessStoryCard.vue';
import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
   components: {
       Icon,Card
   },
   props: {
       succes_story:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
   data() {
    return {
      image:"",
      moreLink:"/home"
    };
  },
  mounted(){
        AOS.init()
  }
}
</script>
<style>
.big-icon {
   font-size: 28px;
}

  @media (min-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1520px;
    }
  }

</style>