<template>
     <expansion-panel>
            <label for="panel6" class="expansion-panel-header"><h6 class="fs-title">Basic Details</h6></label>
            <div class="expansion-panel-content">
                <fieldset>
                <form @submit.prevent="submit">
                    <div class="form-card">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="fieldlabels">Profile Created by:</label>
                                <select required v-model="form.created" :class="{'errors':v$.form.created.$error}" class="form-select" aria-label="Default select example">
                                    <option value="">Select Relationship</option>
                                    <option v-for="item in createdBy" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                                <span v-if="v$.form.created.$error" class="error"> Created by is required <br></span>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Name:</label>
                                <input required v-model="form.name" type="text" :class="{'errors':v$.form.name.$error}" name="name" placeholder="Name" />
                                <span v-if="v$.form.name.$error" class="error"> Name is required <br></span>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Date of Birth:</label> 
                                <input required v-model="form.dob" :class="{'errors':v$.form.dob.$error}" id="datefield" type="date" name="cpwd" placeholder="Confirm Password" />
                                <span v-if="v$.form.dob.$error" class="error"> Date of Birth is required <br></span>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Martial Status:</label>
                                    <select required :class="{'errors':v$.form.marital_status.$error}" v-model="form.marital_status"class="form-select" aria-label="Default select example">
                                        <option value="">Select Status</option>
                                        <option v-for="item in maritalStatus" :value="item?.title" > {{ item?.title }}</option>
                                    </select>
                                    <span v-if="v$.form.marital_status.$error" class="error"> Martial Status is required <br></span>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Religion:</label>
                                <select @change="castAPI" v-model="form.religion" class="form-select" aria-label="Default select example">
                                    <option value="">Select Religion</option>
                                    <option v-for="item in religions" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Caste:</label>
                                <select v-model="form.cast"class="form-select" aria-label="Default select example">
                                    <option selected>Select Caste</option>
                                    <option v-for="item in casts" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Mother tongue:</label>
                                <select v-model="form.mother_tongue" class="form-select" aria-label="Default select example">
                                    <option selected>Select Mother tongue </option>
                                    <option v-for="item in mother_tongue" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Country:</label>
                                <select @change="stateAPI" v-model="form.country" class="form-select" aria-label="Default select example">
                                    <option selected>Select Country</option>
                                    <option v-for="item in country" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">State:</label>
                                <select @change="districtAPI" v-model="form.state" class="form-select" aria-label="Default select example">
                                    <option selected>Select state</option>
                                    <option v-for="item in states" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">District:</label>
                                <select v-model="form.district" class="form-select" aria-label="Default select example">
                                    <option selected>Select district</option>
                                    <option v-for="item in district" :value="item?.title" > {{ item?.title }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Mobile Number:</label> 
                                <input @keypress="isNumber($event)" min="0" v-model="form.mobile" type="text" name="mblnum" placeholder="Mobile number" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Other Mobile Number:</label> 
                                <input @keypress="isNumber($event)" min="0"  v-model="form.mobile1" type="text" name="mblnum" placeholder="Mobile number" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Whatsapp Number:</label> 
                                <input @keypress="isNumber($event)"  min="0" v-model="form.whatsappnumber" type="text" name="mblnum" placeholder="Mobile number" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Postal Code:</label> 
                                <input @keypress="isNumber($event)"  @input="postal" v-model="form.pin" type="text" min="0" name="mblnumz" placeholder="Postal Code" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">Postal :</label> 
                                <input v-model="form.post" type="text" name="mblnumz" placeholder="Postal" />
                            </div>
                            <div class="col-md-6">
                                <label class="fieldlabels">House Name:</label> 
                                <input v-model="form.house_name" type="text" placeholder="House Name" />
                            </div>
                            <div class="col-12">
                                <label class="fieldlabels">Password:</label>
                                <input @input="checkPassword" @change="checkPassword" :class="{'errors':v$.form.password.$error ,'errors':passwordCheck}" v-model="form.password" type="password" name="pwd" placeholder="Password" />
                                <span v-if="v$.form.password.$error" class="error"> {{ v$.form.password.$errors[0].$message.replace('This field','Password') }} <br></span>
                                <span v-if="passwordCheck" class="error"> Invalid Password <br></span>
                            </div>
                        </div>
                    </div> <input type="submit" @click="submit" name="next" class="next action-button" value="Save" />
                </form>                    
                </fieldset>
            </div>
        </expansion-panel>
        <ErrorToast :key="error"  :message="error" @update="errorup" />
        <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { profileCreatedBy,getMotherTongue,country,states,district,mailcheck } from "@/util/Services/register"
import { martial_status,religion,cast } from "@/util/Services/home"
import { required,minLength,maxLength } from "@vuelidate/validators";
import { Panel1 } from "@/util/Services/Authenticated/edit";
import { useVuelidate } from '@vuelidate/core'
import { indianPostAPI } from '@/util/Services/indiaPost';
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue"
export default{
    components:{
        ErrorToast,
        SuccesToast
    },
    data(){
        return{
            v$:useVuelidate(),
            createdBy     : [],
            maritalStatus : [],
            religions     : [],
            casts         : [],
            mother_tongue : [],
            country       : [],
            states        : [],
            district      : [],
            form:{
                created        : this.data?.details?.created,
                name           : this.data?.name,
                dob            : this.data?.details?.dob,
                marital_status : this.data?.details?.marital_status,
                religion       : this.data?.details?.religion,
                cast           : this.data?.details?.cast,
                mother_tongue  : this.data?.details?.mother_tongue,
                country        : this.data?.details?.country,
                state          : this.data?.details?.state,
                district       : this.data?.details?.district,
                mobile         : this.data?.details?.mobile,
                mobile1        : this.data?.details?.mobile1,
                whatsappnumber : this.data?.whatsappnumber,
                post           : this.data?.details?.post,
                house_name     : this.data?.details?.house_name,
                password       : null,
                pin            : this.data?.details?.pin
            },
            error   : null,
            success : null,
            passwordCheck:false
        }
    },
    validations(){
        return{
                form:{
                        created        : {required},
                        name           : {required},
                        dob            : {required},
                        marital_status : {required},
                        mobile         : {required,minLength:minLength(10),maxLength:maxLength(10)},
                        mobile1        : {minLength:minLength(10),maxLength:maxLength(10)},
                        whatsappnumber : {minLength:minLength(10),maxLength:maxLength(10)},
                        post           : {required},
                        password       : {minLength:minLength(8)}
                } 
        } ;
        },
    props:{
      data:{required:true}
    },
    methods:{
        async createdByAPI(){
            await profileCreatedBy().then(r=>{this.createdBy = r?.data?.data});
        },
        async maritalStatusAPI(){
            await martial_status().then(r=>{this.maritalStatus = r?.data?.data});
        },
        async religionAPI(){
            await religion().then(r=>{this.religions = r?.data?.data});
            this.castAPI();
        },
        async castAPI(){
                await cast(this.form.religion).then(r=>{this.casts = r?.data?.data});
        },
        async MotherTongueAPI(){
            await getMotherTongue().then(r=>{this.mother_tongue = r?.data?.data});
        },
        async countryAPI(){
            await country().then(r=>{this.country = r?.data?.data});
            this.stateAPI();
        },
        async stateAPI(){
            if (this.form.country) {
                await states(this.form.country).then(r=>{this.states = r?.data?.data});
                this.districtAPI();
            }
        },
        async districtAPI(){
            if (this.form.state) {
                await district(this.form.state).then(r=>{this.district = r?.data?.data});
            }
        },
        async checkPassword(){
            if (this.form?.mobile) {
                await mailcheck(this.form?.mobile,this.form?.password).then(r=>{this.passwordCheck = !r})
            }
        },
        dob() {
            try {
                    var today = new Date();
                    var dd    = today.getDate();
                    var mm    = today.getMonth(); 
                    var yyyy  = today.getFullYear()-this.age;
                    if(dd<10){ dd='0'+dd } 
                    if(mm<10){ mm='0'+mm } 
                        today = yyyy+'-'+mm+'-'+dd;
                        document.getElementById("datefield").setAttribute("max", today);
                        today = new Date();
                        yyyy = today.getFullYear()-150;
                        today = yyyy+'-'+mm+'-'+dd;
                        document.getElementById("datefield").setAttribute("min", today);
            }
            catch(err) {
                console.log(err.message);
            }        
        },
        genderChange(){
            this.age = this.$store.getters.gender.toLowerCase() == 'male' ? 21:18;
            this.dob(); 
        },
        async postal(){
      let pin =  await indianPostAPI(this.form?.pin);
           if (pin[0]?.PostOffice) {
            // console.log({pin});
            this.form.post = pin[0]?.PostOffice[0]?.Name;
            // console.log(this.form.post);
           }
    },
        submit(){
            this.v$.$validate()
            if(!this.v$.$error && !this.passwordCheck){
                Panel1(this.form).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message})
                // console.log(this.form,'validated');
                setTimeout(() => { this.$emit('submit',true);}, 3000)
            }
        },
        errorup(){
            this.error =null;
            this.success = null;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
    },
    mounted(){
     this.countryAPI();
     this.religionAPI();
     this.createdByAPI();
     this.MotherTongueAPI();
     this.maritalStatusAPI();
     this.genderChange();
    }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
