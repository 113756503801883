<template>
    <div class="container mb-5">
        <div class="row table mb-3">
                <div class=" col-12 col-xl-4 col-lg-3 col-md-6 tr  p-1 " v-for="(item) in carddata" >
                         <Card :item="item" @message-updated="login" />
                        
                </div>
                <button v-if="fulldata?.next_page_url != null" class="btn btn-primary mt-3 showmore col-12 mb-4 mb-lg-0"
                @click="More()" >Show More</button>
        </div>
    </div>
</template>
<style scoped src="@/assets/css/application.css" />
<style scoped>

.showmore {
        background-color: transparent !important;
        display: block;
        width: auto;
        margin: auto;
        text-decoration: underline;
        color: #251718;

    }

    .showmore:hover {
      background-color: transparent !important;
        color: red !important;
    }
</style>
<script>
 import Card from "@/components/Search Page/Card.vue"
 export default {
    props: {
      carddata:{
        type: Array,
        required: true,
      },
      fulldata:{
        type: Array,
        required: true,
      }
    },
    components: {
      Card
    },
    data(){
      return{
        loading:false,
        data:[]
      }
    },
    methods:{
      login(login) {
            // console.log('login');
        this.$emit('message-updated', login);
        },
       async More(){
           this.$emit('more', true);
        }
    },
    
 }
</script>