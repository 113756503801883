<template>
    <div class="row">
          <div class="col-12">
            <div class=" sechead">
              <Icon class="big-icon" icon="twemoji:red-heart"/>
              <h4 class="display-6">Our Gallery</h4>
              <Icon class="big-icon" icon="twemoji:red-heart"/>
            </div>
            <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
              <div class="carousel-indicators d-none">
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class=""
                  aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"
                  class=""></button>
                <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"
                  class="active" aria-current="true"></button>
              </div>
              <div class="carousel-inner">
                        


                <div class="carousel-item active">
                  <div class="d-flex flex-wrap ">
                    <div class="col-md-6 p-1">
                      <img src="@/assets/img/gallery1 a.png" alt="" class="d-block w-100 lazy">
                    </div>
                    <div class="col-md-6 p-1">
                      <img src="@/assets/img/Frame 1000008839 (1).png" alt="" class="d-block w-100 lazy">
                    </div>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="d-flex flex-wrap">
                    <div class="col-md-6 p-1">
                      <img src="@/assets/img/Frame 1000008840.png" alt="" class="d-block w-100 lazy">
                    </div>
                    <div class="col-md-6 p-1">
                      <img src="@/assets/img/gallery1 b.png" alt="" class="d-block w-100 lazy">
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
            
          </div>
        </div>
</template>
<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';

export default {
   components: {
       Icon
   },
   mounted(){
        AOS.init()
  }
}
</script>
<style scoped>
.big-icon {
   font-size: 28px;
}
.row {
  max-height:100%;
  overflow:hide;
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
</style>