<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
      
             <div class="d-flex flex-wrap">
                 <SideNav :active="active" :show="show" />
     
                 <div class="col-lg-9 col-xl-10 col-12 p-0">
                     <div class="d-flex flex-wrap ">
                         <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
     
                         <div class="col-lg-12 col-xl-12  col-12 p-0 sta pt-xl-0 pt-md-5  pt-sm-5 ">
                           <main class="p-0 p-lg-2">
                               <div class="mainbody2 d-flex flex-wrap p-3 p-lg-5 p-3 pt-5 mt-5 pt-lg-5 mt-lg-0 p-lg-5 searchpart">
                                   <div class="container-fluid">
                                     
                                           <div class="row justify-content-around">
                                              
   
                                               <AstrologyView />
                                              
                                              
                                           </div>
   
                                   </div>
   
                               </div>
                           </main>
   
                         </div>
                     </div>
                 </div>
   
             </div>
             <MobileNav :active="active"/>
             <!-- //tosat -->
   
   
   
       
     </body>
     <ErrorToast  :key="error"    :message="error"   @update="errorup" />
     <SuccesToast :key="success"  :message="success" @update="errorup" />
   </template>
   <script>
   import AOS from 'aos'
   import { Icon } from "@iconify/vue";
   import MainNav from "@/components/Authenticated/UserMainNav.vue";
   import SideNav from "@/components/Authenticated/UserSideNav.vue";
   import MobileNav from  "@/components/Authenticated/MobileNav.vue"
   import AstrologyView from "@/components/Authenticated/Astrology/AstrologyView.vue";
   import { searchById,AdvanceSearchAPIs } from '@/util/Services/Authenticated/search';
   import ErrorToast from "@/components/General/ErrorToast.vue"
   import SuccesToast from "@/components/General/SuccessToast.vue"
   import Spinner from "@/components/Authenticated/Spinner.vue"
   
   export default {
     name: "AcceptedView",
     components: {
       MainNav,
       SideNav,
       Icon,
       MobileNav,
       AstrologyView,
       ErrorToast,
       SuccesToast,
       Spinner,
     },
     data() {
       return {
         error:null,
         success:null,
         active:"Astrology",
         show: false,
         loading:false,
       };
     },
     methods: {
       sidenav() {
         this.show = !this.show;
       },
    
      async AdvanceSearchAPI(form){
           this.page = 1;
           this.loading =true;
           this.SearchShow = false;
           this.inputData = form;
           await AdvanceSearchAPIs(form,this.page)
           .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,this.SearchData = r?.data?.data?.data});
           // console.log(form);
       },
      async More(){
         if (this.AdvanceSearchShow == false) {
           this.page++;
          await searchById(this.inputData,this.page)
          .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,r?.data?.data?.data ? this.SearchData.push(...r?.data?.data?.data) : []});
         } else {
           this.page++;
          await AdvanceSearchAPIs(this.inputData,this.page)
          .then(r=>{this.error = r?.response?.data?.message,this.SearchUrl = r?.data?.data,this.loading = false,r?.data?.data?.data ? this.SearchData.push(...r?.data?.data?.data) : []});
         }
         
       },
       errorup(){
           this.$store.commit('SUCCESS_MESSAGE', null);
           // console.log(this.$store.auth)
           this.$store.state.auth.successMessage = null;
           this.success = null;
           this.error = null;
         },
       
     },
     mounted() {
       AOS.init()  
     },
   };
   </script>
   <style scoped src="@/assets/css/application.css" />
   <style scoped>
   .tab-content{
     min-height: 50vh;
     background-color: #EFEFEF;
     padding: 1rem;
     border-radius: 10px;
   }
   .showmore {
           background-color: transparent !important;
           display: block;
           width: auto;
           margin: auto;
           text-decoration: underline;
           color: #251718;
       }
   .showmore:hover {
           background-color: transparent !important;
           display: block;
           width: auto;
           margin: auto;
           text-decoration: underline;
           color: red;
       }
   </style>
   