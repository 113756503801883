<template>
<div v-if="count > 0" class="d-flex pt-4 justify-content-between">
    <h6 class="looktitle m-0  text-black">Discover matches based on your interest :</h6>
        <a :href="matchLink">View More</a>
    </div>
    <div class="row table overflow-y-scroll flex-nowrap">
        <div class="col-12 col-xl-6 col-lg-12   col-md-5 tr p-1 " v-for="(item, index) in data" :key="index">
          {{ count == 0 ? count++:'' }}
            <Card :item="item" />
        </div>
    </div>
                                               
</template>
<script>
  import Card from "@/components/Authenticated/Home/HomeCard.vue";
  import AOS from 'aos';
  import { ref } from 'vue';
  export default {
  setup() {
    const count = ref(0)
    return {
      count
    }
  },
  components: {
        Card
  },
  props: {
      data: {
        required: true,
      },
      matchLink: {
        required: true,
      },
  },
  created() {
      AOS.init()  
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>