<template>
    <a v-if="item?.status != 'active'" :class="{'quick':quick}" class="card sta text-decoration-none shadow-sm m-1 col-12 col-12">
        <img :src="item?.profile_pic" alt="" class="w-25 p-0 lazy shadow-lg">
        <div class="d-grid w-75 p-0 ps-2">
            <!-- <span>{{ item?.user_ids }}</span> -->
            <h6>{{ item?.user_ids }}</h6>
            <!-- v-if="item?.status == 'active'" -->
            <p >{{ item?.age}}yrs {{ item?.details?.height}} | {{ item?.education?.qualification }} | {{ item?.education?.profession }} |
               {{ item?.details?.marital_status }} | {{ item?.details?.cast }} | {{ item?.details?.district }} {{ item?.details?.state}} {{ item?.details?.country }}
                </p>
            <div class="d-flex justify-content-between mt-2">
                <button v-if="item?.matched" :to="{name: 'Home'}" class="callbtn"><Icon icon="ph:phone-call-fill"/>Call Now</button>
                <button v-if="!item?.intrest && !item?.matched" class="interestbtn"><Icon icon="ph:heart-fill"/>Sent Interest</button>
            </div>
        </div>
        <div :href="'/user/'"  class="overlay" > <h2 class="text-danger text-center mt-4"> Cancelled</h2>
        <p v-if="item?.cancelled_reason"  class="text-danger text-center">Reason : {{ item?.cancelled_reason }}</p></div>
    </a>
    <a v-else :class="{'quick':quick}":href="'/user/'+item?.id" class="card sta text-decoration-none shadow-sm m-1 col-12 col-12">
        <img :src="item?.profile_pic" alt="" class="w-25 p-0 lazy shadow-lg">
        <div class="d-grid w-75 p-0 ps-2">
            <!-- <span>{{ item?.user_ids }}</span> -->
            <h6>{{ item?.user_ids }}</h6>
            <!-- v-if="item?.status == 'active'" -->
            <p >{{ item?.age}}yrs {{ item?.details?.height}} | {{ item?.education?.qualification }} | {{ item?.education?.profession }} |
               {{ item?.details?.marital_status }} | {{ item?.details?.cast }} | {{ item?.details?.district }} {{ item?.details?.state}} {{ item?.details?.country }}
                </p>
            <div class="d-flex justify-content-between mt-2">
                <button v-if="item?.matched" :to="{name: 'Home'}" class="callbtn"><Icon icon="ph:phone-call-fill"/>Call Now</button>
                <button v-if="!item?.intrest && !item?.matched" class="interestbtn"><Icon icon="ph:heart-fill"/>Sent Interest</button>
            </div>
        </div>
        
    </a>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
    quick:{}
  },
  data() {
      return {
        innerprofile:"/user/"+this.item?.id,
      };
    },
    mounted() {
      AOS.init()  
    },
  };
  </script>
 <style scoped src="@/assets/css/application.css" />
 <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
 <style scoped src="@/assets/css/components/Authenticated/UserCard.css"/>