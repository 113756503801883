<template>
    <fieldset>
      <form @submit.prevent="next">
     <div class="form-card">
                    <div class="row">
                      <div class="col-12 m-3">
                        <h5 class="fs-title text-center ">Forgot Password</h5>
                      </div>
                     
                    </div> 
                    <label class="fieldlabels">Phone Number / Email : </label> 
                    <input  required @change="checkMail" v-model="form.phone" :class="{'err':v$.form.phone.$error}"  type="text"  id="email"
                            placeholder="Enter Your Phone Number / Email" />
                    <span v-if="v$.form.phone.$error" class="error"> 
                      {{ v$.form.phone.$errors[0].$message == "Value is required" ? "Phone Number / Email is required":v$.form.phone.$errors[0].$message }} 
                      <br>  
                    </span>
                    <br v-else>
                    <span v-if="mailcheck" class="error mt-1"> 
                      {{ "No account found with this Mobile Number. Please check and try again or contact support if needed."}}
                    </span>
                      <br v-else>
    </div>
    <div class="d-flex justify-content-center">
     
      <div v-if="loading" style="color:red;" class="spinner-border m-4 " role="status">
        <span class="sr-only"></span> 
        </div>
        <input v-else @click="next" type="submit" name="next" class="next action-button" value="Submit" />

    </div> 
  </form>
</fieldset>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required,numeric,minLength,maxLength } from '@vuelidate/validators'
import { mailcheck } from "@/util/Services/register"
export default{
  props: {
    loading: {
      required: true,
    },
  },
    data() {
        return {
              v$:useVuelidate(),
              form:{
                      phone : null,
              },
              mailcheck:false,     
        };
    },
    validations(){
      return{
            form:{
                    phone : {required}  
            } 
      } ;
    },
    methods:{
        next(){
          this.v$.$validate()
          if(!this.v$.$error && this.mailcheck == false){
            this.$emit('form-data', this.form);
          }
        }, 
        async checkMail(){
          // if (this.form?.phone && !this.v$.$error) {
          //     await mailcheck(this.form?.phone).then(r=>{this.mailcheck = r})
          // }
        },
        isNumber: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
          } else {
            return true;
          }
        },


    }
}
</script>
<style>
.error{
 color: red;
 text-align:start !important;
 font-size:small !important;
}
.err{
  border-color: red !important;
}
</style>