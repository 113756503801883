<template>
    <fieldset>
                  <div class="form-card">
                    <div class="row">
                      <div class="col-md-12  row " v-if="!uploaded">
                                    <div class="col-md-6 d-flex align-items-center">
                                       <div class="form-group text-start ">
                                           <label for="my_file">Profile pic</label>
                                           <input :key="reload"  type="file" accept="image/png, image/jpeg, image/jpg" @change="previewImage" class="form-control-file" id="my_file">
                                       </div>
                                       <span v-if="v$.image.$error" class="error"> {{ v$.image.$errors[0].$message.replace('This field','Password') }} <br></span>
                                    </div>
                                    <div class="col-md-6">
                                                    <v-card  v-if="preview" width="auto m-2">
                                                      <div class="example">
                                                        <cropper ref="cropper" class="example-cropper" :src="preview" 	:stencil-props="{
                                                          handlers: {},
                                                          movable: false,
                                                          resizable: false,
                                                          aspectRatio: 257/218,
                                                        }"
                                                        :resize-image="{
                                                          adjustStencil: false
                                                        }"
                                                        image-restriction="stencil" />
                                                        <drop-zone
                                                          v-model="preview"
                                                          :label="upload"
                                                          :location="location"
                                                          
                                                          @uploaded="newImagesUploaded"
                                                          class="pb-15"
                                                        />
                                                        <div class="button-wrapper ">
                                                          <input v-if="!loading"   @click="cropImage" type="submit" name="upload" class="next action-button" value="Upload " />
                                                          <!-- <span v-if="!loading" class="button" @click="cropImage"> Upload </span> -->
                                                          <div v-else class="spinner-border mt-4 " role="status"></div>
                                                        </div>
                                                      </div>
                                                    </v-card>
                                    </div>
                                    
                               </div>
                               <div v-else >
                                <input  @click="home" type="button" name="home" class="previous action-button" value="Home" />
                               </div>
                               
                    </div> 
                    
                  </div>
                </fieldset>
                <ErrorToast :key="error"  :message="error" @update="errorup" />
                <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Multiselect from 'vue-multiselect';
import { required } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { profilePic1} from "@/util/Services/Authenticated/edit";
import FsLightbox from "fslightbox-vue/v3";
import VueEasyLightbox from 'vue-easy-lightbox';
export default{
    props: {
    data: {
      required: true,
    },
  },
  components:{
       Multiselect,ErrorToast,SuccesToast,Cropper,FsLightbox,VueEasyLightbox
   },
   data(){
       return{
            v$:useVuelidate(),
            preview: null,
            image: null,
            error:null,
            success:null,
            croppedImageSrc:null,
            loading:false,
            reload:false,
            uploaded: false
       }
   },
 
  
   validations(){
        return{
            image:{required},
        } ;
    },
 
   methods:{
    next(){
            this.$emit('message-updated', 5);
    },
    home(){
      this.$router.push({ path: '/home' })
    },
   async cropImage() {
      const result = this.$refs.cropper.getResult();
      if (result) {
          this.loading =true;
            await  profilePic1(this.dataURLtoFile(result.canvas.toDataURL(
            "image/jpeg"
          )),this.data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false});
              if (this.success) {
                this.image = null;
                this.preview = null;
                this.reload = !this.reload;
                this.uploaded  =true;
              }
              console.log(this.error );
          }
    },
    previewImage: function(event) {
            var input = event.target;
            if (input.files) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            }
            this.image=input.files[0];
            reader.readAsDataURL(input.files[0]);
            }
    },
    dataURLtoFile(dataurl, filename) {
          var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
          while(n--){
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], filename, {type:mime});
    },
    errorup(){
            this.error =null;
            this.success = null;
    },
    
        
 },mounted(){
  
  // this.showSingle();
 }
}
</script>

<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>