<template>
    <div  v-if="error" class="toast align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true">
  <div  class="d-flex">
    <div class="toast-body bg-danger">
     {{ messages[0] }}
    </div>
    <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
</div>
</template>
<style>
.toast{
    display: block !important;
    position: sticky !important;;
    top:50px ;
    right: 10px;
    text-align: center !important;

}
</style>

<script>
 export default {
    props: {
      message:{
        required: true,
      },
    },
    data(){
        return{
           error:false,
           messages:[]
        }
    },
    methods:{
        warnDisabled() {
            this.error = true
            // console.log( this.error)
            setTimeout(() => {
                this.close();
            }, 3500)
          },
          close(){
            this.error = false
            this.$emit('update',true)
          }
    },
    
    mounted(){
        // console.log('error toast',this.message)
        if (this.message) {
          this.messages = this.message.split(',')
            this.warnDisabled()   
        }
            
        
    }
    
 }
</script>