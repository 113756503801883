<template>
    <fieldset>
      <form @submit.prevent="next">
                  <div class="form-card">
                    <div class="row">
                      <div class="col-7">
                        <h5 class="fs-title">Personal Information:</h5>
                      </div>
                      <div class="col-5">
                        <h6 class="steps">Step 2 - 4</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="fieldlabels">Height :</label> 
                        <!-- <input required v-model="form.height" min="0" v-bind:class="{'err':v$.form.height.$error}" type="number" name="height" placeholder="eg: 165,180" /> -->
                        <select v-model="form.height" class="form-select" v-bind:class="{'err':v$.form.height.$error}" aria-label="Default select example">
                          <option value=null>Select Height</option>
                          <option v-for="item in heightOptions" :value="item" >{{ item }}</option>
                        </select>
                        <span v-if="v$.form.height.$error" class="error"> Height is required <br></span>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Weight(kg):</label> 
                        <input  @keypress="isNumber($event)" v-model="form.weight" type="number" min="0" name="weight" placeholder="eg: 45,75" />
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Complexion:</label>
                        <select v-model="form.complexion" class="form-select" aria-label="Default select example">
                          <option value=null>Select type</option>
                          <option v-for="item in complexion" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Body Type:</label>
                        <select required v-model="form.body_type" class="form-select" aria-label="Default select example">
                          <option value="">Select type</option>
                          <option v-for="item in bodyType"  :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Body Status:</label>
                        <select required v-model="form.body_status" class="form-select" aria-label="Default select example">
                          <option value="">Select Status</option>
                          <option v-for="item in bodyStatus"  :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Qualification:</label>
                        <select required v-bind:class="{'err':v$.form.qualification.$error}" v-model="form.qualification" class="form-select" aria-label="Default select example">
                          <option value="">Select Education</option>
                           <option v-for="item in qualification"  :value="item?.title">{{ item?.title }}</option>
                        </select>
                        <span v-if="v$.form.qualification.$error" class="error"> Qualification is required <br></span>
                      </div>
                      <div class="col-md-6">
                        <label class="fieldlabels">Qualification Details: </label> 
                        <input v-model="form.qualification_details"  type="text" name="Details" placeholder="eg: Engineering, Medical..." />
                      </div>
                      <div class="col-6">
                        <label class="fieldlabels">Occupation: </label>
                        <select required v-model="form.occupation" v-bind:class="{'err':v$.form.occupation.$error}"  class="form-select" aria-label="Default select example">
                            <option value="">Select Occupation</option>
                            <option v-for="item in profession" :value="item?.title">{{ item?.title }}</option>
                        </select>
                        <span v-if="v$.form.occupation.$error" class="error"> Occupation is required <br></span>
                      </div>
                      <div class="col-md-12">
                        <label class="fieldlabels">Occupation Details: </label> 
                        <input v-model="form.occupation_details" type="text" name="Details" placeholder="eg: IT, Medical..." />
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Work Location: </label> 
                        <input v-model="form.work_location" type="text" name="Location" placeholder="eg: Chennai, Bengaluru" />
                      </div>
                      <div class="col-12">
                        <label class="fieldlabels">Annual Income: </label>
                        <select v-model="form.annual_income" class="form-select" aria-label="Default select example">
                            <option value="">Select One</option>
                            <option v-for="item in income" :value="item?.title">{{ item?.title }}</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-8 col-lg-8">
                        <label class="fieldlabels">Diet Preference: </label>
                        <div class="d-flex justify-content-between pt-2">
                            <div class="d-flex">
                                <input type="radio" v-model="form.diet_preference" name="fav_language" value="Vegetarian">
                                <label for="html">Vegetarian</label>
                            </div>
                            <div class="d-flex">
                                <input type="radio" v-model="form.diet_preference" name="fav_language" value="Non-vegetarian">
                                <label for="css">Non Vegetarian</label>
                            </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-8 col-lg-8">
                        <label class="fieldlabels">Are you overseas : </label>
                        <div class="d-flex justify-content-between pt-2">
                            <div class="d-flex">
                                <input type="radio" v-model="form.overseas" name="overseas" value=true>
                                <label for="html">Yes</label>
                            </div>
                            <div class="d-flex">
                                <input type="radio" v-model="form.overseas" name="overseas" value=false>
                                <label for="css">No</label>
                            </div>
                        </div>
                      </div>
                    </div>
                    <h5 class="fs-title pt-4">Family Details:</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="fieldlabels">Father's Name:</label>
                            <input v-model="form.father_name" type="text" name="name" placeholder="Full Name" />
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">Father's Occupation:</label>
                            <input v-model="form.father_occupation" type="text" name="Occupation" placeholder="Occupation" />
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">Mother's Name:</label>
                            <input v-model="form.mother_name" type="text" name="name" placeholder="Full Name" />
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">Mother's Occupation:</label>
                            <input v-model="form.mother_occupation" type="text" name="Occupation" placeholder="Occupation" />
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">No Brother's:</label>
                            <input @keypress="isNumber($event)" v-model="form.brother" type="number" min="0" name="number" placeholder="eg:1, 2" />
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">No Brother's Married:</label>
                            <input @keypress="isNumber($event)" v-model="form.married_no_brother" type="number" min="0" name="number" placeholder="eg:1, 2" />
                        </div>
                        <div class="col-md-12">
                            <label class="fieldlabels">About Brother's:</label>
                            <textarea v-model="form.other_details_brother"  name="Detail" form="usrform"> {{form?.other_details_brother ? form?.other_details_brother : "Enter text here..."}}</textarea>
                        </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">No Sister's:</label>
                            <input @keypress="isNumber($event)" v-model="form.sister" type="number" name="number"  min="0" placeholder="eg:1, 2" />
                        </div>

                        <div  class="col-md-6">
                            <label class="fieldlabels"> No Sister's Married:</label>
                            <input @keypress="isNumber($event)" v-model="form.married_no_sisters" type="number"  min="0" name="number" placeholder="eg:1, 2" />
                        </div>
                        <div   class="col-md-12">
                            <label class="fieldlabels">About Sister's:</label>
                            <textarea v-model="form.other_details_sister" name="Detail" form="usrform"> {{form?.other_details_sister ? form?.other_details_sister : "Enter text here..."}}</textarea>
                        </div>
                    </div>
                        <div class="col-md-6">
                            <label class="fieldlabels">House name:</label>
                            <input v-model="form.house_name" type="text" name="number" placeholder="House name" />
                        </div>
                        <div class="col-md-12">
                            <label class="fieldlabels">About Family:</label>
                            <textarea v-model="form.about" name="Detail" form="usrform"> {{form?.about ? form?.about : "Enter text here..."}}</textarea>
                        </div>
                  </div>
                  <input @click="next" type="submit" name="next" class="next action-button" value="Next" />
                  <input @click="prev" type="button" name="previous" class="previous action-button-previous" value="Previous" />
        </form>
   </fieldset>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { complexion,bodyType,qualification,profession,income,bodyStatus } from "@/util/Services/register";
import { heightValue } from '@/util/Services/height';
export default{
    props: {
    current: {
      required: true,
    },
    formData2:{
      required: true,
    }
},
data() {
    return {
                heightOptions : [],
                v$:useVuelidate(),
                form:{
                        //Personal Information
                        height                 : this.formData2?.height ? this.formData2?.height : null,
                        weight                 : this.formData2?.weight ? this.formData2?.weight : "",
                        complexion             : this.formData2?.complexion ? this.formData2?.complexion : null,
                        body_type              : this.formData2?.body_type ? this.formData2?.body_type : "",
                        body_status            : this.formData2?.body_status ? this.formData2?.body_status : "",
                        qualification          : this.formData2?.qualification ? this.formData2?.qualification : "",
                        qualification_details  : this.formData2?.qualification_details ? this.formData2?.qualification_details : "",
                        occupation             : this.formData2?.occupation ? this.formData2?.occupation : "",
                        occupation_details     : this.formData2?.occupation_details ? this.formData2?.occupation_details : "",
                        work_location          : this.formData2?.work_location ? this.formData2?.work_location : "",
                        annual_income          : this.formData2?.annual_income ? this.formData2?.annual_income : "",
                        diet_preference        : this.formData2?.diet_preference ? this.formData2?.diet_preference : "",
                        //Family Details
                        father_name            : this.formData2?.father_name ? this.formData2?.father_name : "",
                        father_occupation      : this.formData2?.father_occupation ? this.formData2?.father_occupation : "",
                        mother_name            : this.formData2?.mother_name ? this.formData2?.mother_name : "",
                        mother_occupation      : this.formData2?.mother_occupation ? this.formData2?.mother_occupation : "",
                        brother                : this.formData2?.brother ? this.formData2?.brother : "",
                        other_details_brother  : this.formData2?.other_details_brother ? this.formData2?.other_details_brother : "",
                        other_details_sister   : this.formData2?.other_details_sister ? this.formData2?.other_details_sister : "",
                        married_no_brother     : this.formData2?.married_no_brother ? this.formData2?.married_no_brother : "",
                        sister                 : this.formData2?.sister ? this.formData2?.sister : "",
                        married_no_sisters     : this.formData2?.married_no_sisters ? this.formData2?.married_no_sisters : "",
                        about                  : this.formData2?.about ? this.formData2?.about : "",
                        house_name             : this.formData2?.house_name ? this.formData2?.house_name : "",
                        overseas               : this.formData2?.overseas ? this.formData2?.overseas : "",
                },
                complexion    :'',
                bodyType      :'',
                qualification :'',
                profession    :'',
                income        :'',
                bodyStatus    :''
    };
},
validations(){
  return{
      form:{
            height        : {required},
            qualification : {required},
            occupation    : {required},
      }             
  };
},
mounted(){
  this.complexionAPI();
  this.bodyTypeAPI();
  this.qualificationAPI();
  this.professionAPI();
  this.incomeAPI();
  this.heightOptions = heightValue();
},
methods:{
    next(){
          this.v$.$validate()
          if(!this.v$.$error){
            this.$emit('form-data', this.form);
            this.$emit('message-updated', this.current+1);
          }
    },
    prev(){
        this.$emit('form-data', this.form);
        this.$emit('message-updated', this.current-1);
    },
    async complexionAPI(){
      await complexion().then(r=>{this.complexion = r?.data?.data});
    },
    async bodyTypeAPI(){
      await bodyType().then(r=>{this.bodyType = r?.data?.data});
      await bodyStatus().then(r=>{this.bodyStatus = r?.data?.data});
    },
    async qualificationAPI(){
      await qualification().then(r=>{this.qualification = r?.data?.data});
    },
    async professionAPI(){
      await profession().then(r=>{this.profession = r?.data?.data});
    },
    async incomeAPI(){
      await income().then(r=>{this.income = r?.data?.data});
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    
},
}
</script>
