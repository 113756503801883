<template>
    
            <div class="card ">
              <div class="card-body p-3 w-100">
                <img :src=image class="lazy" alt=""/>
              <h6> {{item?.heading}}</h6>
              <p>{{item?.namr}}</p>
              </div>
            </div>
        
          
</template>
<script > 

export default {

   props: {
    item:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
    data(){
    return{
      image:this.base_url+this.item?.image
    }
    },
  }
</script>
<style scoped>
img{
 width: 258px !important;
 aspect-ratio: 43/30 !important;
 object-fit: cover;
}
.card{
 min-height: 375px;
}
</style>
