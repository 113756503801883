import axios from 'axios'
import store from '@/store'

let url = process.env.VUE_APP__BASE_URL;
let auth = null;
let token = null;
export const loginApi = async (form) => {
   const data =  axios.post(url+"login",{phone:form?.phone,password:form?.password})
    .then(response => response)
    .catch((error) => error);
     data.then(r=>{
                    store.dispatch('SET_AUTHENTICATED', r?.data?.token ? true:false),
                    store.dispatch('SET_LOGIN', r?.data),
                    store.dispatch('SET_MESSAGE', r),
                    auth = r?.data?.token ? true:false,
                    token = r?.data?.token ? r?.data?.token : null
                    if (auth) {
                      const data = axios.
                  create({
                      headers: {
                          'Authorization': `Bearer ${token}`
                      }
                   }).
                   post(url+"customer/reload-user-details")
                  .then(response => response )
                  .catch((error) => console.log(error));
                  data.then(r=>{
                                  store.dispatch('SET_AUTHENTICATED', r?.data ? true:false),
                                  store.dispatch('UPDATE_LOGIN', r?.data)
                               });
                    }
                 });
                 
    return data;
  };
  export const loginTokenApi = async (user_id,otp) => {
    const data =  axios.post(url+"mobile-otp-verification",{id:user_id,otp:otp})
     .then(response => response)
     .catch((error) => error);
      data.then(r=>{
                     store.dispatch('SET_AUTHENTICATED', r?.data?.token ? true:false),
                     store.dispatch('SET_LOGIN', r?.data),
                     store.dispatch('SET_MESSAGE', r),
                     auth = r?.data?.token ? true:false,
                     token = r?.data?.token ? r?.data?.token : null
                     if (auth) {
                       const data = axios.
                   create({
                       headers: {
                           'Authorization': `Bearer ${token}`
                       }
                    }).
                    post(url+"customer/reload-user-details")
                   .then(response => response )
                   .catch((error) => console.log(error));
                   data.then(r=>{
                                   store.dispatch('SET_AUTHENTICATED', r?.data ? true:false),
                                   store.dispatch('UPDATE_LOGIN', r?.data)
                                });
                     }
                  });
                  
     return data;
   };