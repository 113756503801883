<template>

          <a :href="item?.a"  rel="noopener noreferrer">  <div class="lookbackcard sta">
                <Icon class="lookbackcard-icon" :icon="item?.icon"/>
                <h2>{{ item?.number }}</h2>
                <div class="d-grid">
                    <small class="text-black">{{ item?.head }}</small>
                    <p>{{ item?.p }}</p>
                </div>
            </div>
          </a>
    
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  export default {
    components: {
      Icon,
    },
    props: {
    item: {
      required: true,
    },
  },
  created() {
      AOS.init()  
    },
  };
  </script>
  <style scoped src="@/assets/css/application.css" ></style>
  <style scoped>
  a{
    text-decoration: none;
  }
  h2{
    font-size: 4vh;
  }
   </style>