<template>
     <div id="myCarousel" class=" mt-4 position-relative slide mb-6"
                                        data-bs-ride="carousel">

                                        <!-- <div class="carousel-inner">
                                            <div class="carousel-item " v-for="(item, index) in images" v-bind:class="{'active':index==0}" :key="index">
                                                <img :src="base_url+item?.image" alt="" class="img-fluid lazy">

                                            </div>
                                        </div> -->
                                        <div  class="carousel-indicators mt-4 mb-4 justify-content-start">
                                            <button style="width: auto;height:10rem; margin: 0 1px !important;"type="button" data-bs-target="#myCarousel"  :data-bs-slide-to=index v-for="(item, index) in images" class="active " @click="onShow(index)" :key="index" >
                                                <img :src="item" alt="" class="thumb lazy">
                                            </button>
                                           
                                        </div>

                                        <!-- <button v-if="images.length > 1" class="carousel-control-prev" type="button" data-bs-target="#myCarousel"
                                            data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button v-if="images.length > 1" class="carousel-control-next" type="button" data-bs-target="#myCarousel"
                                            data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button> -->
                                    </div>
                                    <vue-easy-lightbox :visible="visibleRef" :imgs="images" :index="indexRef" @hide="hide" ></vue-easy-lightbox>
</template>
<script>
 import VueEasyLightbox from 'vue-easy-lightbox';
 export default {
    components: { VueEasyLightbox },
    props: {
              images: {required: true},
              base_url:{required: true}
    },
    data() {
        return {
                count:-1,
                visibleRef:false,
                indexRef:0,
            }
        },
    methods:{
        slide(){
           if (this.count >= this.images.length)  this.count = -1;
           this.count++;
        },
        hide(){   this.visibleRef =  false; },
        onShow(id){ 
              this.visibleRef=  !this.visibleRef , this.indexRef = id;
            },
    }
}
</script>
<style scoped src="@/assets/css/application.css" ></style>
<style scoped src="@/assets/css/components/Authenticated/showSlider.css"></style>
<!-- <style scoped>
.carousel-indicators  {
    /* box-sizing: content-box; */
    flex: 0 1 auto;
    width: 100%;
    height: 10rem !important;
    margin: 0 1px;
}
</style> -->
