import axios from 'axios'
let url = process.env.VUE_APP__BASE_URL;
export const forgotPassword = (form) => {
    return axios.post(url+"forgot_password",
        {phone:form?.phone}
    ).then((response) => response).catch((error) => error);
  };
export const OtpCheck = (phone,otp) => {
    return axios.post(url+"otp_check",
        {phone:phone,otp:otp}
    ).then((response) => response).catch((error) => error);
  };
export const UpadtePassword = (user_id,password) => {
    return axios.post(url+"forgot_password_update",
        {user_id:user_id,password:password,password_confirmation:password}
    ).then((response) => response).catch((error) => error);
  };