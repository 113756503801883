<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
  
                      <div class="col-lg-12 col-xl-12  col-12 p-0 pt-xl-0 pt-md-5  pt-sm-5">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                  <li class="nav-item" role="presentation">
                                        <button @click="APICall1" class="nav-link active" id="other-tab" data-bs-toggle="tab"
                                            data-bs-target="#other" type="button" role="tab" aria-controls="other"
                                            aria-selected="true">Accepted By {{ gender }}({{ acceptedProposals_other_link?.total }})</button>
                                    </li>
                                  <li class="nav-item" role="presentation">
                                        <button @click="APICall" class="nav-link " id="by_me-tab" data-bs-toggle="tab"
                                            data-bs-target="#by_me" type="button" role="tab" aria-controls="by_me"
                                            aria-selected="false">Accepted By Me({{ acceptedProposals_byme_link?.total }})</button>
                                    </li>
                                  
                                    
                                </ul>
                                <div class="p-2">
                                    <div class="tab-content" id="myTabContent">
                                        <!-- Accepted By Her -->
                                        <div class="tab-pane fade  " id="by_me" role="tabpanel"
                                            aria-labelledby="by_me-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div :key="acceptedProposals_byme_link" v-if="acceptedProposals_byme_link?.total > 0" class="col-12 col-xl-3 col-lg-6 col-md-6 tr p-1" v-for="(item) in acceptedProposals_byme" >
                                                  <Card  :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12 pt-5  d-flex justify-content-center "> No Data Found</div>
                                            </div>
                                            <button v-if="acceptedProposals_byme_link?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="More" >Show More</button>
                                        </div>
                                         <!-- Accepted By Me -->
                                         <div class="tab-pane fade show active" id="other" role="tabpanel"
                                            aria-labelledby="other-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div :key="acceptedProposals_other_link" v-if="acceptedProposals_other_link?.total > 0" class="col-12 col-xl-4 col-lg-6 col-md-6 tr p-1" v-for="(item) in acceptedProposals_other" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12 pt-5  d-flex justify-content-center "> No Data Found</div>
                                            </div>
                                            <button v-if="acceptedProposals_other_link?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="More1" >Show More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                  </div>
              </div>
  
          </div>
          <MobileNav :active="active"/>
  </body>
</template>
<script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue"
    import Card from "@/components/Authenticated/UserCard.vue"
    import { acceptedProposals_byme,acceptedProposals_other} from '@/util/Services/Authenticated/accepted';
    import Spinner from "@/components/Authenticated/Spinner.vue"
    export default {
      name: "AcceptedView",
      components: {
        MainNav,
        SideNav,
        Icon,
        MobileNav,
        Card,
        Spinner
      },
      data() {
        return {
          active:"AcceptedProfile",
          show: false,
          acceptedProposals_byme_link: [],
          acceptedProposals_byme:[],
          acceptedProposals_other_link:[],
          acceptedProposals_other:[],
          page:1,
          loading:false,
          gender:this.$store.getters.gender == "Male" ? "Her":"Him",
        };
      },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        async APICall(){
             if (this.page == 1) this.loading = true ; this.acceptedProposals_byme = [];
             await acceptedProposals_byme(this.page)
             .then(r=>{this.acceptedProposals_byme_link = r?.data?.data,r?.data?.data?.data ? this.acceptedProposals_byme.push(...r?.data?.data?.data) : []});
             if (this.page == 1) this.spinner();
        },
        async APICall1(){
             if (this.page == 1) this.loading = true; this.acceptedProposals_other = [];
             await acceptedProposals_other(this.page)
             .then(r=>{this.acceptedProposals_other_link = r?.data?.data,r?.data?.data?.data ? this.acceptedProposals_other.push(...r?.data?.data?.data) : []});
             if (this.page == 1) this.spinner();
        },
        More(){
          this.page++;
          this.APICall();
        },
        More1(){
          this.page++;
          this.APICall1();
        },
        spinner(){
              var overlayLoader = document.querySelector('.spinner-border');
              if (overlayLoader) setTimeout(() => overlayLoader.classList.add('fade-out'), 5);
              setTimeout(() =>  this.loading = false, 10);
        },
      },
      mounted() {
        AOS.init() 
        this.APICall(); 
        this.APICall1(); 
      },
      // created(){
      //   this.APICall(); 
      //   this.APICall1(); 
      // }
    };
</script>
  <style scoped src="@/assets/css/application.css" />
  <style scoped src="@/assets/css/no_data.css" />
  <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
   
    