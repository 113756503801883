<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">

              <SideNav :active="active" :show="show" />
  
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">

                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />

                      <div class="col-lg-12 col-xl-12  col-12 p-0 pt-xl-0 pt-md-5  pt-sm-5 ">
                        <main class="p-0 p-lg-3">
                            <div class="mainbody pt-5 mt-4 mt-lg-0 pt-lg-0">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="joined-tab" data-bs-toggle="tab"
                                            data-bs-target="#joined" type="button" role="tab" aria-controls="joined"
                                            aria-selected="true">Shortlist & More({{ shortList?.total }})</button>
                                    </li>
                                </ul>
                                <div class="p-2">
                                    <div class="tab-content" id="myTabContent">
                                        <!-- Just Joined Table -->
                                        <div class="tab-pane fade show active" id="joined" role="tabpanel"
                                            aria-labelledby="joined-tab">
                                            <Spinner v-if="loading" />
                                            <div v-else class="row table">
                                                <div v-if="shortList?.total" class="col-12 col-xl-4 col-lg-6 col-md-6 tr p-1" v-for="(item) in Shortlisted" >
                                                  <Card :item="item" />
                                                </div>
                                                <div v-else class="no_data col-12  d-flex justify-content-center align-items-center"> No Data Found</div>
                                            </div>
                                            <button v-if="shortList?.next_page_url != null && !loading" class="btn btn-primary mt-3 showmore mb-4 mb-lg-0"
                                            @click="More" >Show More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                  </div>
              </div>
          </div>
          <MobileNav :active="active"/>
  </body>
</template>
  
   
    <script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue"
    import Card from "@/components/Authenticated/UserCard.vue"
    import { shortList } from '@/util/Services/Authenticated/shortlist';
    import Spinner from "@/components/Authenticated/Spinner.vue"
    export default {
      name: "AcceptedView",
      components: {
        MainNav,
        SideNav,
        Icon,
        MobileNav,
        Card,
        Spinner
      },
      data() {
        return {
          active:"Shortlisted",
          show: false,
          Shortlisted: [],
          page:1,
          shortList:[],
          loading:false
        };
      },
      methods: {
        sidenav() {
          this.show = !this.show;
        },
        async APICall(){
          if (this.page == 1) this.loading = true;
            await shortList(this.page).then(r=>{this.shortList = r?.data?.data,r?.data?.data?.data ? this.Shortlisted.push(...r?.data?.data?.data) : []});
          if (this.page == 1) this.spinner();
        },
        More(){
          this.page++;
          this.APICall();
        },
        spinner(){
              var overlayLoader = document.querySelector('.spinner-border');
              if (overlayLoader)  setTimeout(() => overlayLoader.classList.add('fade-out'), 5);
              setTimeout(() =>  this.loading = false, 10);
        },
      },
      mounted() {
        AOS.init()  
      },
      created(){
        this.APICall();
      }
    };
    </script>
   <style scoped src="@/assets/css/application.css" />
   <style scoped src="@/assets/css/no_data.css" />
   <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
   
    