import axios from 'axios'
import header from "@/util/Services/Authenticated/header"
import store from '@/store'
let url = process.env.VUE_APP__BASE_URL;

export const userData = () => {
    return axios.post(url+"customer/get-user-data").then((response) => response).catch(error => console.log(error));
};

export const Panel1 = (form) => {
    return axios.post(url+"customer/edit-profile-basic-info",{
        created_by     : form?.created,
        fullname       : form?.name,
        dob            : form?.dob,
        marital_status : form?.marital_status,
        religion       : form?.religion,
        cast           : form?.cast,
        mother_tongue  : form?.mother_tongue,
        country        : form?.country,
        state          : form?.state,
        district       : form?.district,
        mobile         : form?.mobile,
        mobile1        : form?.mobile1,
        whatsappnumber : form?.whatsappnumber,
        post           : form?.post,
        house_name     : form?.house_name,
        password       : form?.password,
        pin            : form?.pin
    }).then((response) => response).catch((error) => error);
};

export const Panel2 = (form) => {
    return axios.post(url+"customer/edit-profile-personal-info",{
        height                : form?.height,//Mandatory
        qualification         : form?.qualification,//Mandatory
        profession            : form?.profession,//Mandatory
        weight                : form?.weight,
        body_type             : form?.body_type,
        complexion            : form?.complexion,
        diet                  : form?.diet_preference,
        qualification_details : form?.qualification_details,
        profession_details    : form?.profession_details,
        workplace             : form?.workplace,
        income                : form?.income,
        blood_group           : form?.blood_group,
        body_status           : form?.body_status,
        about                 : form?.about
    }).then((response) => response).catch((error) => error);
};

export const Panel3 = (form) => {
    return axios.post(url+"customer/edit-profile-family-info",{
        father_name           : form?.father_name,
        father_occupation     : form?.father_occupation,
        mother_name           : form?.mother_name,
        mother_occupation     : form?.mother_occupation,
        no_brother            : form?.no_brother,
        no_sisters            : form?.no_sisters,
        married_no_brother    : form?.married_no_brother,
        married_no_sisters    : form?.married_no_sisters,
        about_family          : form?.about,
        other_details_brother : form?.other_details_brother,
        other_details_sister  : form?.other_details_sister,
    }).then((response) => response).catch((error) => error);
};

export const Panel4 = (form) => {
    return axios.post(url+"customer/edit-profile-prefered-partner-info",{
        pref_partner_height_from : form?.height_from,
        pref_partner_height_to   : form?.height_to,
        pref_partner_age_from    : form?.age_from,
        pref_partner_age_to      : form?.age_to,
        pref_qualification       : form?.qualification? JSON.stringify(form?.qualification):JSON.stringify([]),
        pref_profession          : form?.profession? JSON.stringify(form?.profession):JSON.stringify([]),
        pref_country             : form?.country,
        pref_state               : form?.state,
        pref_district            : form?.district,
        pref_partner_religions   : form?.religion,
        pref_partner_caste       : form?.caste
    }).then((response) => response).catch((error) => error);
};

export const profilePic = (image) => {
    return axios.post(url+"customer/user-profile-update",{image:image},{ headers: { "Content-Type": "multipart/form-data"}})
    .then((response) => response).catch((error) => error);
};
export const profilePic1 = (image,user_id) => {
    return axios.post(url+"user-profile-update",{image:image,user_id:user_id},{ headers: { "Content-Type": "multipart/form-data"}})
    .then((response) => response).catch((error) => error);
};
export const otherImage = (images) => {
    return axios.post(url+"customer/user-multipleimage-update",{images:images},{ headers: { "Content-Type": "multipart/form-data"}})
    .then((response) => response).catch((error) => error);
};
export const uploadDocuments = (documents) => {
    return axios.post(url+"customer/upload/documents",{documents:documents},{ headers: { "Content-Type": "multipart/form-data" }})
    .then((response) => response).catch((error) => error);
};

export const pendingProfilePic = () => {
    return axios.post(url+"customer/get-pending-profile-image").then((response) => response).catch(error => console.log(error));
};
export const pendingImages = () => {
    return axios.post(url+"customer/get-pending-multiple-images").then((response) => response).catch(error => console.log(error));
};
export const removeImages = (imageId) => {
    return axios.post(url+"customer/remove-pending-multiple-images",{imageId:imageId}).then((response) => response).catch((error) => error);
};
export const pendingDocuments = () => {
    return axios.post(url+"customer/get-pending-document-images").then((response) => response).catch(error => console.log(error));
};
export const removeDocuments = (documentId) => {
  return axios.post(url+"customer/remove-documents",{documentId:documentId}).then((response) => response).catch(error => error);
};
export const approvedImages = () => {
  return axios.get(url+"customer/approved-multiple-images").then((response) => response).catch(error => console.log(error));
};
export const removeApprovedImages = (imageId) => {
  return axios.post(url+"customer/remove-approved-multiple-images",{imageId:imageId}).then((response) => response).catch(error => error);
};

//horoscope image
export const uploadHoroscopeImage = (image) => {
  return axios.post(url+"customer/add-horoscope-image",{image:image},{ headers: { "Content-Type": "multipart/form-data"}})
  .then((response) => response).catch((error) => error);
};
export const pendingHoroscopeImage = () => {
  return axios.get(url+"customer/pending-horoscope-image").then((response) => response).catch(error => console.log(error));
};
export const approvedHoroscopeImage = () => {
  return axios.get(url+"customer/approved-horoscope-image").then((response) => response).catch(error => console.log(error));
};
export const rejectedHoroscopeImage = () => {
  return axios.get(url+"customer/rejected-horoscope-image").then((response) => response).catch(error => console.log(error));
};
export const removeHoroscopeImage = (id) => {
  return axios.post(url+"customer/remove-horoscope-image",{imageId:id}).then((response) => response).catch(error => error);
};

//family image 
export const uploadFamilyImage = (images) => {
  return axios.post(url+"customer/add-family-image",{images:images},{ headers: { "Content-Type": "multipart/form-data"}})
  .then((response) => response).catch((error) => error);
};
export const pendingFamilyImage = () => {
  return axios.get(url+"customer/pending-family-image").then((response) => response).catch(error => console.log(error));
};
export const approvedFamilyImage = () => {
  return axios.get(url+"customer/approved-family-image").then((response) => response).catch(error => console.log(error));
};
export const rejectedFamilyImage = () => {
  return axios.get(url+"customer/rejected-family-image").then((response) => response).catch(error => console.log(error));
};
export const removeFamilyImage = (id) => {
  return axios.post(url+"customer/remove-family-image",{imageId:id}).then((response) => response).catch(error => error);
};

//Succes story image 
export const uploadSuccesstoryImage = (image,heading,about) => {
  return axios.post(url+"customer/add-public-success-stories",{image:image,heading:heading,about:about},{ headers: { "Content-Type": "multipart/form-data"}})
  .then((response) => response).catch((error) => error);
};
export const pendingSuccesstoryImage = () => {
  return axios.get(url+"customer/get-pending-success-storie").then((response) => response).catch(error => console.log(error));
};
export const approvedSuccesstoryImage = () => {
  return axios.get(url+"customer/get-approved-success-storie").then((response) => response).catch(error => console.log(error));
};




