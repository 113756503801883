<template>
    <div class="col-md-12">
        <div class="inner-form">
            <div class="basic-search">
                <label class="label" for="search by id">Astrology Check</label>                  
                    <div class="row">                    
                        <div class="col-12 col-sm-6 col-md-4">
                            <label class="fieldlabels">Select Boys Star:</label>
                                <select v-model="mstar" :class="{'errors':v$.mstar.$error}"   class="form-select" aria-label="Default select example">
                                    <option  value="" >Select Star</option>
                                    <option v-for="(item) in stars"  :value="item?.title" >{{ item?.title }}</option>
                                </select>
                                <span v-if="v$.mstar.$error" class="error"> {{ v$.mstar.$errors[0].$message }} <br></span>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <label class="fieldlabels">Select Girls Star:</label>
                                <select v-model="fstar" :class="{'errors':v$.fstar.$error}"   class="form-select" aria-label="Default select example">
                                    <option  value="" >Select Star</option>
                                    <option v-for="(item) in stars"  :value="item?.title" >{{ item?.title }}</option>
                                </select>
                                <span v-if="v$.fstar.$error" class="error"> {{ v$.fstar.$errors[0].$message }} <br></span>
                        </div>

                        <div class="position-relative d-flex justify-content-center mt-4 col-12 col-sm-6 col-md-2">
                            <div class="input-field searchingkey w-100 d-flex justify-content-center">
                                
                                <div class="group-btn d-flex justify-content-center">
                                    <button class="btn-search" @click="submit" >Check</button>
                                </div>
                            </div>
                       </div>
                        
                        
                                                             
                    </div>
                    <div class="col-12 col-md-8 text-center " v-if="match?.nomath" >
                        <label class="label " for="search by id">Matching Count : {{match?.nomath+"("+match?.match+")"}}</label> 
                    </div>
                  
            </div>

        </div>
    </div>
                                            
    
</template>
<style scoped src="@/assets/css/application.css" />
<style scoped>
    .label {
        color: red;
        font-size: calc(1.8rem - 1vh);
        padding-bottom: .5rem;
        font-weight: 600;
    }
   
    
</style>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getStar } from "@/util/Services/register";
import { starMatch } from "@/util/Services/Authenticated/Astrology";
import { ref } from 'vue';
export default {
  name: "AcceptedView",
 
  setup() {
          const mstar                  = ref("");
          const fstar                  = ref("");
          const stars                  = ref([]);
          const match                  = ref({});

                async function star(){
                    await getStar().then(r=>{stars.value =r?.data?.data});
                }
                async function matchCheck(){
                    await starMatch(mstar.value,fstar.value).then(r=>{match.value = r?.data?.data});
                }
        
          return {
            mstar,fstar,stars,match,star,matchCheck
          }
      },
  data() {
    return {
        v$:useVuelidate(),
    }
  },
  validations(){
  return{
          mstar : {required},
          fstar : {required},
        };
  },
  methods:{
 
    submit(){
        this.v$.$validate()
        if(!this.v$.$error){
            this.matchCheck();
       }
    },
  },
  mounted(){
    if (this.$store.getters.religion != 'Hindu') {
        this.$router.push('/user/home');
    }
  },
  created(){
    this.star();
  },
  
}
</script>
<style scoped>
label{
  padding-top: 1rem;
}
.errors {
      border: 1px solid red !important;
    }
</style>