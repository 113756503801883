<template>
     <!-- Required Stylesheets -->

        <!-- Required scripts -->
 
    <title>Marryhelp</title>
     <header id="header" class="fixed-top header-transparent p-md-2 p-2" :class="{'header-scrolled':scroll}">
    <div class="container-fluid">
     
      <BNavbar  class="navbar navbar-expand-lg ">
          <div class="container-fluid">
            <a :href="homelink">
                <img  src="@/assets/img/logowithr.png" alt="marryHelp" class="img-fluid d-block d-lg-none"/>
            </a>
            <button @click="transparentChange" class="navbar-toggler border-0" id="toggleButton" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <Icon icon="gg:menu-right-alt"/>
            </button>
            <div class="collapse navbar-collapse justify-content-lg-center justify-content-start" id="navbarSupportedContent">
              <a :href="homelink"><img src="@/assets/img/logowithr.png" alt="" class="img-fluid d-none d-lg-block"></a>
              <ul class="navbar-nav text-start w-100 gap-0 gap-lg-5 justify-content-lg-center justify-content-start mb-2 mb-lg-0 border-0 pt-4 pt-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" :href="homelink" >Home</a>
                </li>
                
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Service
                  </a>
                  <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" :href="freeMembership">Free Membership</a></li>
                    <li><a class="dropdown-item" :href="paidMembership">Paid Membership</a></li>
                    
                    <!-- <li><a class="dropdown-item" :href="CSR">CSR Activity</a></li> -->
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :href="successStories">Success Stories</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " :href="help" >Help</a>
                </li>
              </ul>
              <div  v-if="!$store.getters.isAuthenticated" class=" d-flex justify-content-between  grid gap-2 gap-lg-4 px-4 logbtn align-items-center">
                  <a @click="login" class="addButton col-sm ">Login</a>
                  <a :href="register" class="col-sm">Register</a>
              </div>
              <div  v-else  class=" d-flex justify-content-end  grid gap-2 gap-lg-4 px-4 logbtn align-items-center">
                  <a  :href="userHome">Dashboard</a>
              </div>
            </div>
          </div>
        </BNavbar >
      </div>
   
  </header>

</template>
<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';
export default {
  components: {
       Icon
   },
   
  
  data() {
    return {
      homelink: "/home",
      freeMembership:"/free_membership",
      paidMembership:"/paid_membership",
      CSR:"/csr",
      successStories:"/succes_stories",
      help:"/help",
      register:"/register",
      userHome:'user/home',
      scroll:false,
      show:false
    };
  },
    methods:{
      transparentChange(){
        var actualInnerWidth = document.body.clientWidth;
        
        if (actualInnerWidth < 979 && this.scroll == false) {
          this.scroll = true;
        }
      },
      handleSCroll (event) {

        if (window.scrollY > 100 ) {
          this.scroll = true;
        }else{
          this.scroll = false;
        }
      },
      login() {
        this.$emit('login', true);
        // console.log('login')
        },
      
    },
    created () {
      window.addEventListener('scroll', this.handleSCroll);

    },
    destroyed () {
      window.removeEventListener('scroll', this.handleSCroll);
    },
   
    mounted() {
    AOS.init()
    // console.log('jo');
    this.handleSCroll();
  },

}   
</script>
