<template>
    <div class="col-12 p-0">
                        <header class="p-3 py-1 py-lg-2 p-lg-2">
                            <div class="headerbar m-1">
                                <div class="d-flex justify-content-between align-items-center p-lg-3 p-0">
                                    <button @click="sidenav" v-bind:class="{'open':show}" class="menubtn d-grid d-lg-none" id="nav-icon4">

                                        <span></span>
                                        <span></span>
                                        <span></span>

                                    </button>
                                    <nav class="d-lg-block d-none nav-lg">
                                        <ul class="list-unstyled d-flex gap-4 m-0">
                                            <li v-bind:class="{'active':active == 'Home'}">
                                                <a :href="homeLink"><Icon class="iconify-icon" icon="solar:home-2-broken"/> Home</a>
                                            </li>
                                            <li v-bind:class="{'active':active == 'Match'}" >
                                                <a :href="matchLink" class="d-flex">
                                                    <img :class="{'gray':active != 'Match'}" src="@/assets/Marryhelp logo.png" alt="" class="img-fluid ps-3">
                                                    Matches
                                                </a>
                                            </li>
                                            <li v-bind:class="{'active':active == 'Search'}">
                                                <a :href="searchLink">
                                                    <Icon class="iconify-icon" icon="iconamoon:search"/>
                                                    Search
                                                </a>
                                            </li>
                                            <li v-bind:class="{'active':active == 'Inbox'}">
                                                <a :href="inboxLink">
                                                    <Icon class="iconify-icon" icon="quill:inbox"/>
                                                    Inbox
                                                </a>
                                            </li>
                                            <!-- <li v-bind:class="{'active':active == 'Outbox'}">
                                                <a :href="outboxLink">
                                                    <Icon class="iconify-icon" icon="quill:outbox"/>
                                                    Outbox
                                                </a>
                                            </li> -->
                                        </ul>
                                    </nav>
                                    <img src="@/assets/img/logowithr.png" alt="" class="d-lg-none d-block">
                                    <!-- <div class="d-flex gap-3">
                                        <div id="google_translate_element"></div>
                                        <button class="headericon dropbtn" @click="notification">
                                            <Icon class="iconify-icon" icon="mingcute:notification-fill"/>
                                            <div id="myDropdown" v-bind:class="{'show':notifi}" class="dropdown-content">
                                                <ul class="list-unstyled" >
                                                    <li v-for="(item,index) in noification" :key="index">
                                                       <Card :item="item"/>
                                                    </li>
                                                </ul>
                                            </div>
                                        </button>
                                        <button class="headericon"><Icon class="iconify-icon"ify-icon
                                                icon="ri:customer-service-fill"/></button>
                                    </div> -->
                                </div>

                            </div>
                        </header>
                    </div>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  import Card from "@/components/Authenticated/NotifivcationCard.vue"
  export default {
    name: "UserMainNav",
    components: {
      Icon,
      Card
    },
    props: {
    show: {
      type: Boolean,
      required: true,
    },
    active: {
      required: true,
    },
  },
    data() {
      return {
        homeLink:"/user/home",
        matchLink:"/user/matches/0",
        searchLink:"/user/search",
        inboxLink:"/user/inbox",
        premiumLink:"/user/home",
        outboxLink:"/user/inbox",
        notifi:false,
        noification:[
            {
                image:"https://www.marryhelp.in/asset/img/girl1.png",
                message:"Niya Varghese viewed your profile. Do you like her?",
                link:"/user/home"
            },
            {
                image:"https://www.marryhelp.in/asset/img/girl2.png",
                message:"Niya Varghese viewed your profile. Do you like her?",
                link:"/user/home"
            },
            {
                image:"https://www.marryhelp.in/asset/img/girl3.png",
                message:"Niya Varghese viewed your profile. Do you like her?",
                link:"/user/home"
            },
            {
                image:"https://www.marryhelp.in/asset/img/girl4.png",
                message:"Niya Varghese viewed your profile. Do you like her?",
                link:"/user/home"
            },
            {
                image:"https://www.marryhelp.in/asset/img/girl1.png",
                message:"Niya Varghese viewed your profile. Do you like her?",
                link:"/user/home"
            },
        ]
        
       
      };
    },
    methods: {
      sidenav() {
        this.$emit('nav-show', false);
      },
      notification() {
        this.notifi = !this.notifi;
      },
    },
    mounted() {
      AOS.init()  
    },
  };
  </script>
     <style scoped src="@/assets/css/application.css" ></style>
     <style scoped>
     .gray{
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            height: 25px !important;
     }
     .img-fluid{
         height: 30px;
     }
    </style>
