<template>
  <div class="content d-flex justify-content-center">
    <div class="card">
      <img class="col-12" :src="image" alt="" />
      <div class="card-content p-3">
        <div class="card-innerhead">
          <h6>{{ item?.user_ids }}</h6>
          <Icon icon="ph:arrow-up" />
        </div>
        <div class="card-innerbody">
          <small><Icon icon="material-symbols:verified" />ID Verified</small>
          <h6>{{ item?.user_ids }}</h6>
          <p>{{ item?.age }} yrs, {{ item?.height }}</p>
          <p>{{ item?.cast }}</p>
          <p>{{ item?.profession }}</p>
          <p>{{ item?.state }}{{  item?.city }}</p>
          <a @click="login" class="moredetailbtn"> View Detail</a>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- <script setup>
const data = defineProps(["item"]);
</script> -->
<style scoped>
.card{
  max-width: fit-content;
}
</style>
<script > 

export default {

   props: {
    item:{
        required: true,
      },
      base_url:{
        required: true,
      }
    },
    data(){
    return{
      image:this.base_url+this.item?.profile_pic,
      innerprofile:"/user/"+this.item?.id,
    }
    },
    methods:{
      login() {
            // console.log('login');
            if (this.$store.getters.isAuthenticated) {
              this.$router.push(this.innerprofile)
            }
        this.$emit('login', true);
       
        }
    }
  }
</script>
<style scoped>
img{
 height: 248px !important;
 width: 199px !important;
 object-fit: cover;
}
</style>
