<template>
    <!-- Required Stylesheets -->

       <!-- Required scripts -->

   <title>Marryhelp</title>
    <header id="header" class="fixed-top header-transparent p-md-2 p-2 header-scrolled">
   <div class="container-fluid">
    
     <BNavbar  class="navbar navbar-expand-lg ">
         <div class="container-fluid">
           <a :href="homelink">
               <img  src="@/assets/img/logowithr.png" alt="marryHelp" class="img-fluid d-block d-lg-none"/>
           </a>
           <button class="navbar-toggler border-0" id="toggleButton" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
             <Icon icon="gg:menu-right-alt"/>
           </button>
           <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
             <a :href="homelink"><img src="@/assets/img/logowithr.png" alt="" class="img-fluid d-none d-lg-block"></a>
             <ul class="navbar-nav w-100 gap-0 gap-lg-5 justify-content-center mb-2 mb-lg-0 border-0 pt-4 pt-lg-0">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" :href="homelink" >Home</a>
                </li>
                <li class="nav-item">
                      <a class="nav-link " v-bind:class="{ 'active':navAbout }" aria-current="page" :href="about_us">About us</a>
                </li>
                <li class="nav-item">
                      <a class="nav-link " v-bind:class="{ 'active':navMessage }" aria-current="page" :href="message">Message</a>
                </li>
               <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle"  v-bind:class="{ 'active':navService}" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Service
                    </a>
                    <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" v-bind:class="{ 'active':navFreeMembership }" :href="freeMembership">Free Membership</a></li>
                        <li><a class="dropdown-item" v-bind:class="{ 'active':navPaidMembership }" :href="paidMembership">Paid Membership</a></li>
                        
                        <!-- <li><a class="dropdown-item" v-bind:class="{ 'active':navCSR }" :href="CSR">CSR Activity</a></li> -->
                    </ul>
               </li>
               <!-- <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle"  v-bind:class="{ 'active':navPayment}" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Payment Plan
                      </a>
                      <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" v-bind:class="{ 'active':navUpgarde}" :href="upgrade">Upgrade</a></li>
                            <li><a class="dropdown-item" v-bind:class="{ 'active':navRegular}" :href="regular">Re Regular</a></li>
                            <li><a class="dropdown-item" v-bind:class="{ 'active':navCompare}" :href="compare">Compare Plans</a></li>
                      </ul>
                </li> -->
               <li class="nav-item">
                     <a class="nav-link"  v-bind:class="{ 'active':navSuccessStories }" :href="successStories">Success Stories</a>
               </li>
               <li class="nav-item">
                     <a class="nav-link "  v-bind:class="{ 'active':navHelp }" :href="help" >Help</a>
               </li>
             </ul>
             <div class="d-lg-flex d-none grid gap-2 gap-lg-4 px-4 logbtn align-items-center">
              
               <a v-if="!$store.getters.isAuthenticated" @click="show =true" class="addButton">Login</a>
   
               <a v-if="!$store.getters.isAuthenticated" :href="register">Register</a>
               <a  v-else :href="userHome">Dashboard</a>
             </div>
           </div>
         </div>
       </BNavbar >
     </div>
  
 </header>
 <Login v-model="show" />
</template>
<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';
import Login from "@/components/General/Login.vue";
export default {
 components: {
      Icon,Login
  },
  props: {
    navactive: {
      type: String,
      required: true,
    },
    logintrigger:{required: true}
  },
 data() {
   return {
     homelink: "/home",
     freeMembership:"/free_membership",
     paidMembership:"/paid_membership",
     CSR:"/csr",
     successStories:"/succes_stories",
     help:"/help",
     register:"/register",
     about_us:"/about_us",
     message:"/message",
     upgrade:"/upgarde",
     regular:"/regular",
     compare:"/compare",
     navAbout:false,
     navHelp:false,
     navMessage:false,
     navService:false,
     navSuccessStories:false,
     navFreeMembership:false,
     navPaidMembership:false,
     navCSR:false,
     navPayment:false,
     navUpgarde:false,
     navRegular:false,
     navCompare:false,
     show:false,
     userHome:'user/home',
   };
 },
   methods:{
     
    
     check(){
      if (this.navactive == "success") {
          this.navSuccessStories = true;
      } else if(this.navactive == "help"){
          this.navHelp = true;
      } else if (this.navactive == "free membership") {
          this.navService = true;
          this.navFreeMembership = true;
      } else if (this.navactive == "paid membership") {
          this.navService = true;
          this.navPaidMembership = true;
      } else if (this.navactive == "csr") {
          this.navService = true;
          this.navCSR = true;
      } else if (this.navactive == "message") {
          this.navMessage = true;
      } else if (this.navactive == "about") {
          this.navAbout    = true;
      } else if (this.navactive == "upgrade") {
          this.navPayment = true;
          this.navUpgarde = true;
      } else if (this.navactive == "regular") {
          this.navPayment = true;
          this.navRegular = true;
      } else if (this.navactive == "compare") {
          this.navPayment = true;
          this.navCompare = true;
      } 

     }
   },
   mounted() {
    this.show = this.logintrigger;
    this.check();
   AOS.init()
  //  console.log(this.$store.getters.isAuthenticated,'auth')
 },

}   
</script>

