<template>
    <div class="d-flex justify-content-between">
            <div class="profiledetails sta">
                    <span v-if="data?.user_data?.status == 'active'" class="verified">
                        <Icon icon="ic:baseline-verified"/>Verified
                    </span>
                    <span class="idno">{{ data?.user_data?.user_ids }}</span>
                    <!-- <h2>{{ data?.user_data?.name }}</h2> -->
            </div>
                                        <div v-if="data?.user_data?.Intrest != false " class="interest">
                                            Approve the interest
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button @click="Approve" >Approve</button>
                                                <button @click="Reject" >Reject</button>
                                            </div>
                                        </div>
         
    </div>
    <p class="text-start">{{ data?.user_data?.details?.about }}</p> 
    <hr>
    <p class="sta">{{ data?.user_data?.age }} yrs, {{ data?.user_data?.details?.height }}c
        | {{ data?.user_data?.education?.qualification }} | {{ data?.user_data?.education?.profession }} 
        | {{ data?.user_data?.details?.marital_status }} | {{ data?.user_data?.details?.religion }},
        {{ data?.user_data?.details?.cast }} | {{ data?.user_data?.details?.district }}, 
        {{ data?.user_data?.details?.state }}, {{ data?.user_data?.details?.country }}
    </p>

    <hr class="my-4">
        <div class="d-flex profbtn">
            <div class="profinnerbtn">
                <button @click="Shortlist"  :class="{'active':shortlist == true}" >
                    <Icon icon="f7:suit-heart"/>
                </button>  
                <span>Shortlist</span>
            </div>
            <div class="profinnerbtn">
                <button @click="PhoneCall" :class="{'inactive':data?.user_data?.matched != true}">
                    <Icon icon="solar:phone-calling-rounded-linear"/>
                </button>
                <span>Call Admin</span>
            </div>
            <div class="profinnerbtn">
                <button @click="Interest" :class="{'green':data?.user_data?.matched == true,'active':data?.user_data?.Intrest1 == true }">
                    <Icon icon="mingcute:heart-hand-line"/>
                </button>
                <span>Interest</span>
            </div>
            <!-- <div class="profinnerbtn">
                <button>
                    <Icon icon="hugeicons:saturn-01"/>
                </button>
                <span>Astrology</span>
            </div> -->
            <!-- show if family image is there -->
            <div v-if="imgsRef.length > 0 " class="profinnerbtn">
                <button  @click="onShow">
                    <Icon icon="carbon:pedestrian-family"/>
                </button>
                <span>Family Images</span>
            </div>

            <div class="profinnerbtn">
                <button @click="SaveMactch" :class="{'active':savedmatch == true}" >
                    <Icon icon="mage:inbox-download"/>
                </button>
                <span>Save</span>
            </div>

      </div>
      <!-- Using light box to show the images -->
    <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" @hide="hide" ></vue-easy-lightbox>
    <ErrorToast  :key="error"    :message="error"   @update="errorup" />
    <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<style scoped src="@/assets/css/application.css" ></style>
<script>
    import { Icon } from "@iconify/vue";
    import { addShortlist,removeShortlist,addSavedMatch,removeSavedMatch,
             addIntrest,removeIntrest,ApproveIntrese,RejectIntrese } from "@/util/Services/Authenticated/userShow";
    import ErrorToast from "@/components/General/ErrorToast.vue";
    import SuccesToast from "@/components/General/SuccessToast.vue";
    import VueEasyLightbox from 'vue-easy-lightbox';
    export default {
        props: { data: { required: true } ,imgsRef : { required: true } },
        components: { Icon, ErrorToast, SuccesToast ,VueEasyLightbox },
        data(){
            return{
                shortlist:this.data?.user_data?.shortlist,
                error:null,
                success:null,
                savedmatch:this.data?.user_data?.savedmatch,
                visibleRef:false,
                indexRef:0,
            }
        },
        methods:{
            PhoneCall(){
                if (this.data?.user_data?.matched == true) {
                    window.open('tel: 94477 70147');
                }
            },
            onShow(){ 
              this.visibleRef=  !this.visibleRef , this.indexRef = 0;
            },
            hide(){   this.visibleRef =  false; },
            Shortlist(){
                if (!this.shortlist) {
                    //Add to ShortList
                    addShortlist(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message ,this.shortlist = r?.data?.message? true : false});
                } else {
                    //Remove from ShortList
                    removeShortlist(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.shortlist = r?.data?.message? false : true});
                }
            },
            Interest(){
                if (this.data?.user_data?.matched == true) {
                    this.$swal.fire({
                                title: "Are you sure?",
                                text: "This may cause cancellation of the interest request.",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "red",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, cancel it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeIntrest(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                                        if (this.success)  this.reload();
                                    }
                                });
                    
                } else {
                    let message = null
                    if (this.$store.getters.gender == 'Male') {
                        if (this.$store.getters.dob > this.data?.user_data?.details?.dob) {
                            message = "The bride's age is greater than your age. Please verify the details.";
                        }
                        if (this.$store.getters.height < this.data?.user_data?.details?.height) {
                            if (message) {
                                 message = "The bride's age and height are both greater than yours. Please verify the details."
                            }else{
                                message = "The bride's height is greater than your height. Please verify the details.";
                            }
                        }
                        
                    } else {
                        // console.log("dob",this.$store.getters.dob,this.data?.user_data?.details?.dob);
                        if (this.$store.getters.dob < this.data?.user_data?.details?.dob) {
                            message = "The groom's age is less than yours. Please verify the details";
                            // console.log({message});
                        }
                        if (this.$store.getters.height > this.data?.user_data?.details?.height) {
                            if (message) {
                                 message = "The groom's age and height are both less than yours. Please verify the details"
                            }else{
                                message = "The groom's height is less than yours. Please verify the details.";
                            }
                        }
                    }
                    if (message) {
                        this.$swal.fire({
                                title: "Are you sure?",
                                text: message,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "red",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, add intrest!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        addIntrest(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                                         if (this.success)  this.reload();
                                    }
                                });
                    }else{
                        addIntrest(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                        if (this.success)  this.reload();
                    }
                   
                }
            },
            SaveMactch(){
                if (!this.savedmatch) {
                    //Add to Saved Match
                    addSavedMatch(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message ,this.savedmatch = r?.data?.message? true : false})
                } else {
                    //Remove to Saved Match
                    removeSavedMatch(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.savedmatch = r?.data?.message? false : true});
                }
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            reload(){
                this.$router.go(0)
            },
            async Approve(){
                 await ApproveIntrese(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                 this.reload();
            },
            async Reject(){
                await RejectIntrese(this.data?.user_data?.id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
                this.reload();
            }
        }
    }
</script>
<style scoped>
.active{
    font-weight: bolder !important;
    border: red 2px solid !important;
}
.inactive{
    color: DarkGrey !important;
}
.green{
    font-weight: bolder !important;
    border: green 2px solid !important;
    color: green !important;
}
</style>