import axios from 'axios'
import header from "@/util/Services/Authenticated/header"
let url = process.env.VUE_APP__BASE_URL;
export const addRequest = (methode,count,remarks,visiting_date,user_id) => {
    return axios.post(url+"customer/sent-user-request",{masterId:methode,count:count,remarks:remarks,visiting_date:visiting_date,visited_to:user_id})
                .then((response) => response).catch((error) => error);
  };
export const getPendingRequest = () => {
    return axios.get(url+"customer/pending-user-requests")
                .then((response) => response).catch((error) => console.log(error));
  };
export const getApprovedRequest = () => {
    return axios.get(url+"customer/approved-user-requests")
                .then((response) => response).catch((error) => console.log(error));
  };
export const removeRequest = (id) => {
    return axios.post(url+"customer/reject-user-request",{reqId:id})
                .then((response) => response).catch((error) => error);
  };
export const RequestFor = () => {
    return axios.get(url+"customer/user-request-master")
                .then((response) => response).catch((error) => error);
  };
export const Visiting = () => {
    return axios.get(url+"customer/visiting-user")
                .then((response) => response).catch((error) => error);
  };
export const Recevied = () => {
    return axios.get(url+"customer/request-user-request")
                .then((response) => response).catch((error) => error);
  };
export const Feedback = () => {
    return axios.get(url+"customer/get-user-requests_feedbacks")
                .then((response) => response).catch((error) => error);
  };