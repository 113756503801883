import { createApp } from 'vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'vue-croppa/dist/vue-croppa.css'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'aos/dist/aos.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'
import store from './store'
import Croppa from 'vue-croppa'
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import VueEasyLightbox from 'vue-easy-lightbox/external-css'
import moment from 'moment'


createApp(App)
.use(router)
.use(moment)
.use(VueEasyLightbox)
.use(Croppa)
.use(VueSweetalert2)
.use(store)
// .use(BootstrapVue)
// .use(IconsPlugin)
.mount('#app')
