<template>
     <div class="contact_info_sec">
                                  <div class="d-flex info_single py-3 sta text-white">
                                    <Icon class="big-icon" icon="fa-solid:address-card"/>
                                      <p class=" m-0">NRA 136, Opp.Muthoot Capital View Flat,
Nandavanam Samadhimadam Road, Palayam,
Thiruvananthapuram - 695 033</p>
                                  </div>
                                  <div class="d-flex info_single py-3 ">
                                    <Icon class="big-icon" icon="ic:round-phone-in-talk"/>
                                      <a href="#">
                                        +91 9387116659,+91 94959 70147</a>
                                  </div>
                                  <div class="d-flex info_single py-3 ">
                                    <Icon class="big-icon" icon="material-symbols-light:mail"/>
                                      <a href="mailto:marryhelp.in@gmail.com">marryhelp.in@gmail.com</a>
                                  </div>
                                  
                                  <div class="socil_item_inner py-3 d-flex">
                                    
                                     
                                      <a href="#" class="pl-0 pr-3"><Icon class="big-icon" icon="eva:facebook-fill"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="mage:x"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="formkit:youtube"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="lets-icons:insta"/></a>
                            
                                    <a href="#" class="pl-3 pr-3"><Icon class="big-icon" icon="ri:linkedin-fill"/></a>
                                    
                                 </div>
                              </div>
    </template>
     <script > 
     import { Icon } from '@iconify/vue';
     export default {
        components: {
            Icon
        },
     
     }
     </script>
     <style scoped>
     .big-icon {
        font-size: 28px;
        margin-right:20px;
        color:white;
     }
     p{
        color:white;
     }
     a{
        color:white;
     }

     
     </style>