import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SuccesStorey from '@/views/SuccesStory.vue';
import Help from '@/views/HelpView.vue';
import FreeMembership from '@/views/FreeMembership.vue';
import PaidMembership from '@/views/PaidMembership.vue';
import CSR from '@/views/CSRView.vue';
import UpgradeView from '@/views/UpgradeView.vue';
import RegularView from '@/views/RegularView.vue';
import CompareView from '@/views/CompareView.vue';
import RegisterView from '@/views/RegisterView.vue';
import SearchPage from "@/views/HomeSearchPage.vue";
//Authenticated
import UserHomeView from '@/views/Authenticated/HomeView.vue';
import UserShow from "@/views/Authenticated/UserShow.vue";
import AcceptedProposals from "@/views/Authenticated/AcceptedView.vue"; 
import ShortList from "@/views/Authenticated/ShortListView.vue";
import SavedMatches from "@/views/Authenticated/SavedMatchesView.vue";
import MatchView from "@/views/Authenticated/MatchView.vue";
import SearchView from "@/views/Authenticated/SearchView.vue";
import UserQuickSearch from "@/views/Authenticated/QuickSearhPage.vue";
import InboxView from '@/views/Authenticated/InboxView.vue';
import UserEditView from "@/views/Authenticated/UserEditView.vue";
import EditImageView from '@/views/Authenticated/EditImageView.vue';
import ForgotPasswordView from '@/views/ForgotPasswordView.vue';
import PendingProposalView from '@/views/Authenticated/PendingProposalView.vue';
import AstrologyView from '@/views/Authenticated/AstrologyView.vue';
import RequestView from '@/views/Authenticated/RequestView.vue';
import store from '@/store'
const routes = [
  {
    path: '/', name: 'home', component: HomeView
  },
  {
    path: '/home',name: 'Home', component: HomeView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Ho.vue')
  },
  { path: '/succes_stories',name: 'SuccesStorey',component: SuccesStorey
  },
  {
    path: '/help',name: 'Help',component: Help
  },
  {
    path: '/free_membership', name: 'FreeMembership', component: FreeMembership
  },
  {
    path: '/paid_membership',
    name: 'PaidMembership',
    component: PaidMembership
  },
  {
    path: '/csr',
    name: 'CSR',
    component: CSR
  },
  {
    path: '/about_us',
    name: 'AboutUsView',
    component: () => import( '@/views/AboutUsView.vue')
    // component: AboutUsView
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import( '@/views/MessageView.vue')
  },
  {
    path: '/Home',
    name: 'Login',
    component: () => import( '@/views/LoginView.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import( '@/views/PrivacyView.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import( '@/views/FAQView.vue')
  },
  {
    path: '/Advertise',
    name: 'Advertise',
    component: () => import( '@/views/AdvertiseView.vue')
  },
  {
    path: '/Wedding',
    name: 'Wedding',
    component: () => import( '@/views/WeddingView.vue')
  },
  {
    path: '/Career',
    name: 'Career',
    component: () => import( '@/views/CareerView.vue')
  },
  {
    path: '/Pricing',
    name: 'Pricing',
    component: () => import( '@/views/PricingView.vue')
  },
  {
    path: '/Terms&Conditions',
    name: 'Terms&Conditions',
    component: () => import( '@/views/Terms&Condition.vue')
  },
  
  {
    path: '/upgarde',
    name: 'UpgradeView',
    component: UpgradeView
  },
  {
    path: '/regular',
    name: 'RegularView',
    component: RegularView
  },
  {
    path: '/compare',
    name: 'CompareView',
    component: CompareView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  //Forgot Password
  {
    path: '/forgot_password',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView
  },
  {
    path: '/search/:form',
    name: 'SearchPage',
    component: SearchPage,
    props: true
  },
  //Authenticated
  {
    path: '/user',
    meta: { requiresAuth: true },
    children: [
      { path: 'home',                        name: 'UserHome',            component : UserHomeView },
      { path: '/user/:id',                   name: 'UserShow',            component: () => import( '@/views/Authenticated/UserShow.vue')  , props: true},
      { path: 'shortlist',                   name: 'ShortList',           component : ShortList },
      { path: 'accepted-proposals',          name: 'AcceptedProposals',   component : AcceptedProposals },
      { path: 'saved-matches',               name: 'SavedMatches',        component : SavedMatches },
      { path: 'matches/:id',                 name: 'MatchView',           component: () => import( '@/views/Authenticated/MatchView.vue') , props: true},
      { path: 'search',                      name: 'SearchView',          component : SearchView },
      { path: 'quick-search',                name: 'UserQuickSearch',     component : UserQuickSearch },
      { path: 'inbox',                       name: 'InboxView',           component : InboxView },
      { path: 'profile_edit/:id',            name: 'UserEditView',        component : UserEditView ,props: true},
      { path: 'profile_image',               name: 'EditImageView',       component : EditImageView },
      { path: 'pending-proposals',           name: 'PendingProposalView', component : PendingProposalView },
      { path: 'astrology',                   name: 'AstrologyView',       component : AstrologyView },
      { path: 'requests/:id',                name: 'RequestView',         component : RequestView ,props: true},
      
    ],
  },
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // console.log(process.env.BASE_URL,process.env.VUE_APP__BASE_URL);
  if (to.matched.some((record) => record.meta.requiresAuth) && store.getters.isAuthenticated != true) next({ name: 'Login' }) 
    next()
       
})

export default router
