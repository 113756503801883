<template>
  <!-- Navigation bar for Home -->
  <Nav1 @login="login" />
  <Login  v-model="show" />
  <!-- Main Sider in Home -->
  <Slider :slide="slide" :martialOptions="martialOptions" />
  <!-- Second Section  -->
  <Section2 />
  <!-- Quick registration -->
  <Register  :captchastring="captchastring" :martialOptions="martialOptions"  @message-updated="captchajoins"/>
  <!-- Premium match sliader -->
  <Premium :gallery="galleryItems" :key="galleryItems" :base_url="base_url"  @login="login" />
  <div class="site-section bg-white position-relative" style="z-index: 0">
    <div class="container">
      <Gallery />
      <Couple :succes_story="succes_story" :base_url="base_url" />
    </div>
  </div>
  <Footer />
</template>
<script>
// @ is an alias to /src
import Nav1 from "@/components/Home/HomeNavbar.vue";
import Slider from "@/components/Home/HomeSlider.vue";
import Section2 from "@/components/Home/HomeSection2.vue";
import Register from "@/components/Home/HomeRegister.vue";
import Premium from "@/components/Home/Premium.vue";
import Gallery from "@/components/Home/Gallery.vue";
import Couple from "@/components/Home/Couple.vue";
import Footer from "@/components/General/Footer.vue";
import Login from "@/components/General/Login.vue";
import { HomeAPI ,martial_status} from "@/util/Services/home";
import { ref } from 'vue';
export default {
  name: "HomeView",
  components: {
    Nav1,
    Slider,
    Section2,
    Register,
    Premium,
    Gallery,
    Couple,
    Footer,
    Login
  },
  
  setup() {
          
          const captchastring          = ref("");
          const slide                  = { gender: ["Female", "Male"] };
          const martialOptions         = ref([]);
          const data                   = ref([]);
          const show                   = ref(false);
          const succes_story           = ref([]);
          const galleryItems           = ref([]);
          const base_url               = ref("");
          async function Callapis(){
              await HomeAPI().then(r => {data.value = r?.data?.data ,base_url.value = r?.data?.data?.base_url});
              succes_story.value = data.value?.success_stories;
              galleryItems.value = data.value?.premium_users;
             
            }
          return {
            captchastring,slide,Callapis,martialOptions,data,succes_story,galleryItems,base_url,show
          }
      },
 
  methods: {
    
    captchajoins(){
      try {
            var charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
            var lengthOtp = 6;
            var captcha = [];
            for (var i = 0; i < lengthOtp; i++) {
              // Below code will not allow Repetition of Characters
              var index = Math.floor(Math.random() * charsArray.length); // Get the next character from the array
              if (captcha.indexOf(charsArray[index]) == -1)
                captcha.push(charsArray[index]);
              else i--;
            }
            this.captchastring = captcha.join("");
          }
          catch(err) {
            console.log(err);
          }
    },
    async martialLoad(){
          await martial_status().then(r => { this.martialOptions = r?.data?.data });
          // console.log(this.martialOptions)
        },
        login() {
          this.show = true;
          console.log(this.show );
        },
  },

  created(){
    this.Callapis();
    this.martialLoad();
  }
};
</script>
