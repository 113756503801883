<template>
  <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
        <div class="d-flex flex-wrap">
            <SideNav :active="active" :show="show" />

            <div class="col-lg-9 col-xl-10 col-12 p-0">
                <div class="d-flex flex-wrap ">
                    <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />


                    <div class="col-12 p-0">
                        <main class="p-0 p-lg-2 pt-5 mt-4 mt-lg-0 pt-lg-0">
                            <div class="mainbody2 p-4">

                               <Section1 :data="data" />
                                <Journey :key="JourneyData" :data="JourneyData"/>

                               <AcceptedInterest :matchLink="acceptedMatch" :data="data?.accepted_users" v-if="data?.accepted_users"/>
                                <div class="row">
                                    <div class="col-md-6">
                                        <BasedonMatch :matchLink="matchLink" :data="data?.discover_matches" />
                                        <Explore />
                                    </div>
                                    <div class="col-md-6 pt-4">
                                       <LoveFound />
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>



        </div>
        <MobileNav :active="active"/>
        <SuccesToast :key="success"  :message="success" @update="errorup" />
</body>
  </template>

 
  <script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  import MainNav from "@/components/Authenticated/UserMainNav.vue";
  import SideNav from "@/components/Authenticated/UserSideNav.vue";
  import MobileNav from  "@/components/Authenticated/MobileNav.vue"
  import Section1 from "@/components/Authenticated/Home/Section1.vue"
  import Journey from "@/components/Authenticated/Home/Journey.vue"
  import AcceptedInterest from "@/components/Authenticated/Home/AcceptedInterest.vue"
  import BasedonMatch from "@/components/Authenticated/Home/Interest.vue"
  import Explore from "@/components/Authenticated/Home/Explore.vue"
  import LoveFound from "@/components/Authenticated/Home/LoveFound.vue"
  import SuccesToast from "@/components/General/SuccessToast.vue"
  import {home} from "@/util/Services/Authenticated/home"
  import { ref } from 'vue';
  export default {
    name: "UserHomeView",
    setup() {
            const acceptedMatch       = '/user/accepted-proposals';
            const matchLink           = "/user/matches/1";
            const active              = "Home";
            const show                = ref(false);
            const data                = ref({});
            const JourneyData         = ref([
                                                  {
                                                      icon:'fluent:link-person-24-regular',
                                                      number:0,
                                                      head:"New Members",
                                                      p:"Match your Preference",
                                                      a:"/user/matches/1"
                                                  },
                                                  {
                                                      icon:'fluent:person-sync-32-regular',
                                                      number:0,
                                                      head:"Members",
                                                      p:"Viewed Your Profile",
                                                      a:"/user/matches/5"
                                                  },
                                                  {
                                                      icon:'fluent:person-star-28-regular',
                                                      number:0,
                                                      head:"Members",
                                                      p:"Accepted your interest",
                                                      a:"/user/accepted-proposals"
                                                  },
                                             ]);
            async function APICall(){
                    await home().then(r=>{data.value = r.data});
                    JourneyData.value[0]['number']  = data.value?.match_your_preference;
                    JourneyData.value[1]['number']  = data.value?.viewed_your_profile;
                    JourneyData.value[2]['number']  = data.value?.accepted_inerest;
                      
             }
            return {
              acceptedMatch,matchLink,active,show,data,APICall,JourneyData
            }
        },
    components: {
      MainNav,SideNav,Icon,
      MobileNav,Section1,Journey,AcceptedInterest,
      BasedonMatch,Explore,LoveFound,SuccesToast
    },
    data() {
      return {
          success:this.$store.state.auth.successMessage,
      };
    },
    methods: {
      sidenav() { this.show = !this.show; },
      errorup()
      {
        this.$store.commit('SUCCESS_MESSAGE', null);
        this.$store.state.auth.successMessage = null;
        this.success = null;
      },
    },
    created(){
        this.APICall(); 
        AOS.init() 
      }
  };
  </script>
 <style scoped src="@/assets/css/application.css" />
 <style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>
 
  