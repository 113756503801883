<template>
    <table class="table table-striped mt-4">
        <thead>
            <tr>
                <th scope="col">Request For</th>
                <th scope="col">Count</th>
                <th scope="col">Visiting Date</th>
                <th scope="col">Visited to</th>
                <th scope="col">User Remark</th>
                <th scope="col">Admin Remark</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data">
                <td>{{ item?.master_req?.title }}</td>
                <td>{{ item?.count }}</td>
                <td>{{ item?.visiting_date ?  item?.visiting_date : ""}}</td>
                <td><a v-if="item?.visited_to" :href="'/user/'+item?.visited_to?.id" target="_blank" rel="noopener noreferrer">{{ item?.visited_to?.user_ids }}</a></td>
                <td>{{ item?.details  }}</td>
                <td>{{ item?.remarks }}</td>
                <td class="redColor pt-3" :class="{'text-warning':item?.status == 'pending'}">{{ item?.status }}</td>
                <td v-if="item?.status == 'pending'" class="p-3"><button @click="remove(item?.id)"  type="button" class="btn btn-danger">Remove</button></td>
                <td v-else ></td>
            </tr>         
        </tbody>
    </table>
    <ErrorToast :key="error"  :message="error" @update="errorup" />
    <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { ref } from 'vue';
import { removeRequest } from '@/util/Services/Authenticated/request';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
export default {
  name: "RequestView",
  components:{
    ErrorToast,SuccesToast
  },
  props: {
    data: { required: true },
    link: { required: true },
  },
  setup() {
          const error                = ref(null);
          const success              = ref(null);
          function errorup(){
                error.value = null;
                success.value = null;
          }
          return {
           errorup,error,success
          }
      },
  methods:{
    async remove(id){
       await removeRequest(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message});
       this.$emit('reload', true);
    }
  },
}
</script>
<style scoped>
.redColor{
    color: red;
}
</style>
