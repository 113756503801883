export default {
    state: {
      gender:'',
      martial_status:'',
      religion:"",
      cast:"",
      ageTo:'',
      ageFrom:''
    },
    getters: {
      quick_gender         : state => state.gender,
      quick_martial_status : state => state.martial_status,
      quick_religion       : state => state.religion,
      quick_cast           : state => state.cast,
      ageTo                : state => state.ageTo,
      ageFrom              : state => state.ageFrom,
      
    },
    mutations: {
        gender (state,gender) { state.gender = gender; },
        martial_status (state,martial_status) { state.martial_status = martial_status; },
        religion (state,religion) { state.religion = religion; },
        cast (state, cast) { state.cast = cast; },
    },
    actions: {
       SET_QUICK_FORM({commit}, value){
            commit('gender', value?.gender),
            commit('martial_status', value?.martial_status),
            commit('religion', value?.religion),
            commit('cast', value?.cast)
            commit('ageTo', value?.ageTo),
            commit('ageFrom', value?.ageFrom)
       },
    },
    modules: {
    }
  }