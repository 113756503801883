<template>
    <expansion-panel>
       <label for="panel6" class="expansion-panel-header"><h6 class="fs-title">Upload Family Images</h6></label>
           <div class="expansion-panel-content">
               <fieldset>
                   <div class="form-card">
                       <div id="app" class="container my-3">
                           <div class="row">
                                <div class="col-md-12 row">
                                    <h5>Upload Family Images</h5>
                                    <div class="col-md-5 d-flex align-items-center">
                                        <div class="form-group text-start ">
                                            <label for="my-file">Select Image</label>
                                            <input :key="reload" type="file" accept="image/png, image/jpeg, image/jpg" multiple="multiple" @change="previewMultiImage" class="form-control-file" id="my_file">
                                        </div>
                                    </div>

                                    <div class="col-md-7 row">
                                        <div class=" p-2 mt-3 d-flex"  >
                                            <template v-if="preview_list.length" >
                                                <div v-for="item, index in preview_list" :key="index" class="containerimg m-1">
                                                    <img :src="item" class="img-fluid  shadow-lg" />
                                                    <button @click="remove(index)" class="button">X</button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="col-md-12 d-flex justify-content-center">
                                        <div v-if="!loading" > 
                                            <input v-if="preview_list.length" type="submit" @click="submit" name="next" class="next action-button" value="Upload" /> 
                                        </div>
                                        <div v-else class="spinner-border " role="status"></div>
                                    </div>

                                    <h5 v-if="pending.length" class="mt-3">Uploaded Family Image</h5>
                                    <div v-if="pending.length"  class="col-md-12 row">
                                        <div class="col-md-2 col-sm-6 p-2 d-flex justify-content-center" v-for="(item,index) in pending" >
                                            <div class="containerimg  " @click="onShow(index)" >
                                                <img :src="base_url+item?.fam_image" class="img-fluid  shadow-lg lazy" />
                                                <button @click="removePending(item?.id)" class="button">X</button>
                                            </div>
                                        </div>
                                        <vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" @hide="hide" ></vue-easy-lightbox>
                                    </div>

                                    <h5 v-if="approved.length" class="mt-3">Approved Family Image</h5>
                                    <div v-if="approved.length"  class="col-md-12 row">
                                        <div class="col-md-2 col-sm-6 p-2 d-flex justify-content-center" v-for="(item,index) in approved" >
                                            <div class="containerimg  " @click="onShow1(index)" >
                                                <img :src="base_url+item?.fam_image" class="img-fluid  shadow-lg lazy" />
                                                <button @click="removePending(item?.id)" class="button">X</button>
                                            </div>
                                        </div>
                                        <vue-easy-lightbox :visible="visibleRef1" :imgs="imgsRef1" :index="indexRef1" @hide="hide" ></vue-easy-lightbox>
                                    </div>

                                    <h5 v-if="rejected.length" class="mt-3">Rejected Family Image</h5>
                                    <div v-if="rejected.length"  class="col-md-12 row">
                                        <div class="col-md-2 col-sm-6 p-2 d-flex justify-content-center" v-for="(item,index) in rejected" >
                                            <div class="containerimg  " @click="onShow2(index)" >
                                                <img :src="base_url+item?.fam_image" class="img-fluid  shadow-lg lazy" />
                                                <button @click="removePending(item?.id)" class="button">X</button>
                                            </div>
                                        </div>
                                        <vue-easy-lightbox :visible="visibleRef2" :imgs="imgsRef2" :index="indexRef2" @hide="hide" ></vue-easy-lightbox>
                                    </div>

                                </div>
                           </div>
                       </div>
                   </div>
               </fieldset>
           </div>
       </expansion-panel>
       <ErrorToast :key="error"  :message="error" @update="errorup" />
       <SuccesToast :key="success"  :message="success" @update="errorup" />
</template>
<script>
import { ref } from 'vue';
import Multiselect from 'vue-multiselect';
import VueEasyLightbox from 'vue-easy-lightbox';
import ErrorToast from "@/components/General/ErrorToast.vue";
import SuccesToast from "@/components/General/SuccessToast.vue";
import { uploadFamilyImage,pendingFamilyImage,approvedFamilyImage,rejectedFamilyImage,removeFamilyImage } from '@/util/Services/Authenticated/edit';
export default{
    components:{ Multiselect,ErrorToast,SuccesToast,VueEasyLightbox },
    setup() {
        const visibleRef   = ref(false);
        const visibleRef1  = ref(false);
        const visibleRef2  = ref(false);
        const remove       = ref(false);
        const reload       = ref(false);
        const loading      = ref(false);
        const error        = ref(null);
        const success      = ref(null);
        const base_url     = ref(null);
        const indexRef     = ref(0); 
        const indexRef1    = ref(0); 
        const indexRef2    = ref(0); 
        const imgsRef      = ref([]);
        const imgsRef1     = ref([]);
        const imgsRef2     = ref([]);
        const pending      = ref([]);
        const approved     = ref([]);
        const rejected     = ref([]);
        const preview_list = ref([]);
        const image_list   = ref([]);
        
        const onShow = (index) => { 
           
            if (!remove.value)  visibleRef.value =  !visibleRef.value , indexRef.value = index;
        }
        const onShow1 = (index) => { 
           
            if (!remove.value) visibleRef1.value =  !visibleRef1.value , indexRef1.value = index;
        }
        const onShow2 = (index) => { 
            
            if (!remove.value) visibleRef2.value =  !visibleRef2.value , indexRef2.value = index;
        }
        const removeCheck = () => { 
            remove.value = !remove.value ;
            
        }
        const hide = () => { visibleRef.value =  false , visibleRef1.value =  false , visibleRef2.value =  false; }
    
        async function getPending(){
            let Arrays  = [];
            let Arrays1 = [];
            let Arrays2 = [];
                await pendingFamilyImage().then(r=>{Arrays = r?.data?.data ? r?.data?.data : [],pending.value = r?.data?.data ? r?.data?.data : [],r?.data?.base_url ? base_url.value = r?.data?.base_url : null});
                imgsRef.value  = Arrays.map(myFunction);
                await approvedFamilyImage().then(r=>{Arrays1 = r?.data?.data ? r?.data?.data : [],approved.value = r?.data?.data ? r?.data?.data : [],r?.data?.base_url ? base_url.value = r?.data?.base_url : null});
                imgsRef1.value = Arrays1.map(myFunction);
                await rejectedFamilyImage().then(r=>{Arrays2 = r?.data?.data ? r?.data?.data : [],rejected.value = r?.data?.data ? r?.data?.data : [],r?.data?.base_url ? base_url.value = r?.data?.base_url : null});
                imgsRef2.value = Arrays2.map(myFunction);

                function myFunction(num) {
                    return base_url.value+num?.fam_image;
                }
        }
        return {
            visibleRef,indexRef,imgsRef,visibleRef1,indexRef1,imgsRef1,approved,visibleRef2,indexRef2,imgsRef2,rejected,
            base_url,pending,preview_list,image_list,reload,loading,error,success,
            hide,getPending,onShow,onShow1,onShow2,removeCheck
        }
   },
   props:{ data:{required:true} },
   methods:{
            previewMultiImage: function(event) {
                var input = event.target;
                var count = input.files.length;
                var index = 0;
                if (input.files) {
                    while(count --) {
                        var reader = new FileReader();
                        const arr = ['jpeg','png','jpg'];
                        const substr = input.files[index].type;
                        const substr1 = substr.split('/')
                        const subArr = arr.some(str => str.includes(substr1[1]));
                      
                        if (subArr) {
                            reader.onload = (e) => {
                                this.preview_list.push(e.target.result);
                            }
                            this.image_list.push(input.files[index]);
                            reader.readAsDataURL(input.files[index]);
                            index ++;
                        } else {
                            this.$swal.fire({
                                                icon: "error",
                                                title: "Oops...",
                                                text: "Only upload JPG, JPEG, or PNG files.!",
                                            });
                        }
                    }
                }
                this.reload = !this.reload;
            },
            remove(index){
                    this.preview_list.splice(index, 1);
                    this.image_list.splice(index, 1);
                    this.reload = !this.reload;
            },
            async submit(){
                this.loading = true;
                await uploadFamilyImage(this.image_list).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.loading = false,this.getPending()});
                if (this.success) {
                    this.preview_list = [];
                    this.image_list = [];
                    this.reload = !this.reload;
                }
            },
            errorup(){
                this.error =null;
                this.success = null;
            },
            
            async removePending(id){
              
                this.removeCheck();
                this.$swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "red",
                                cancelButtonColor: "black",
                                confirmButtonText: "Yes, delete it!"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        removeFamilyImage(id).then(r=>{this.error = r?.response?.data?.message,this.success = r?.data?.message,this.getPending()});
                                    }
                                    this.removeCheck();
                                });
                                
            }
            
 },
 created(){
    this.getPending();
 }
}
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/edit.css"></style>
<style scoped>
.preview{
    height: 109px !important;
    width:  128.5px !important;
}
img{
   height: 109px !important;
   width: 128.5px !important;
   object-fit: cover;
   object-position: 10% 15%;
}

.containerimg {
  position: relative;
  height: 109px !important;
  width: 128.5px !important;
}

.containerimg img {
    height: 109px !important;
    width: 128.5px !important;
    object-fit: cover;
}

.containerimg .button {
  position: absolute;
  top: 0%;
  left: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.spinner-border{
    color: #af0b0b;
}

</style>
