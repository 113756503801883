
<template>
<div v-bind:class="{ 'd-block':modelValue }" class="modal" id="modalbox" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content ">
        <div class="modal-header modalmain">
          <button @click="login" id="removeButton"><Icon icon="mingcute:close-fill"/></button>

        </div>
        <div class="modal-body">
          <h4 class="m-0 pink-text">Let’s Get Started Now!</h4>
          <p class="mb-3 grey-text">Login and find your life partner</p>
          <form v-if="otp" class="sta" @submit.prevent="onSubmit" >
            <label class="sta"  for="userid">Phone Number / Email</label> <br>
            <input type="text"   v-model="form.phone" v-bind:class="{'err':v$.form.phone.$error}" placeholder="Phone Number / Email"><br>
            <span v-if="v$.form.phone.$error" class="error"> 
              {{ v$.form.phone.$errors[0].$message == "Value is required" ? "Phone Number / Email is required":v$.form.phone.$errors[0].$message }}  <br></span>
            <label class="sta"  for="paswd">Password</label><br>
            <input type="password" v-model="form.password" v-bind:class="{'err':v$.form.password.$error}" placeholder="Password"><br>
            <span v-if="v$.form.password.$error" class="error"> Password is required <br></span>
            <input class="sta" checked type="checkbox"> <small >Remember me on this device</small> <br>
            <div class="d-flex pt-3 justify-content-between align-items-center">
              <button  type="submit"    class="mt-2 submit"> Submit </button>

              <a  type="submit"   href="/register" class="mt-2 submit1"> Register </a>
            
              <a :href="forgotpassword" class="forgotpswd">Forgot Password?</a>
            </div>
          </form> 
          <form v-else class="sta" @submit.prevent="otpSubmit" >
              <label class="sta"  for="userid">Add OTP</label> <br>
              <input type="number"  @input="otpCheck()" @keypress="isNumber($event)" v-model="otpdata" v-bind:class="{'err':otperror}" placeholder="OTP"><br>
              <span v-if="otperror" class="error"> OTP required <br></span>
              
              <div class="d-flex pt-3 justify-content-between align-items-center">
                <button  type="submit"    class="mt-2 submit"> Submit </button>
               
              </div>
              
          </form> 
        </div>
        
      </div>
    </div>
    <SuccesToast :key="success"  :message="success" @update="errorup" />
    <ErrorToast :key="error"  :message="error" @update="errorup" />
  </div>
  
</template>

<script>
import AOS from 'aos'
import { Icon } from '@iconify/vue';
import { useVuelidate } from '@vuelidate/core'
import { required, email,numeric,minLength,maxLength } from '@vuelidate/validators'
import { loginApi ,loginTokenApi} from '@/util/Services/login';
import ErrorToast from "@/components/General/ErrorToast.vue"
import SuccesToast from "@/components/General/SuccessToast.vue";
export default {
    props: {
      modelValue: {
      type: Boolean,
      required: true,
    },
  },
  components: {
       Icon,ErrorToast,SuccesToast
   },
   
  data() {
    return {
      shows:this.modelValue,
      model:"d-block",
      forgotpassword:"/forgot_password",
      otp:true,
      success:null,
      otpdata:null,
      otperror:null,
      user:null,
      v$:useVuelidate(),
      form:{
            phone:'',
            password:''
      },
      error:null,
    };
  },
  validations(){
  return{
    form:{
                phone:{required},
                password:{required}, 
    }
   
                
  } ;
},
  methods:{
   
        login() {
            this.$emit('update:modelValue', false)
        },
        async onSubmit() {
          this.v$.$validate()
     
          if(!this.v$.$error){
            this.user = null;
              await loginApi(this.form)
              .then(r=>{this.user = r?.data?.user,r?.data?.user?.email_verified_at ? null : this.otp = false,this.error = r?.response?.data?.message,console.log({r}),this.success = r?.data?.message})
              // console.log(this.user,this.otp,'otp')
              if (this.error) {
                this.otp = true;
              }
              if(!this.error && this.otp){
                  this.$router.push('/user/home') 
              }
              
          }
        },
        async otpSubmit(){
          if (this.otpdata) {
            await loginTokenApi(this.user?.id,this.otpdata)
              .then(r=>{this.error = r?.response?.data?.message,console.log({r}),this.success = r?.data?.message})
              if(!this.error){
                  this.$router.push('/user/home') 
              }
          }else{
            this.otperror = true;
          }
          
        },
        otpCheck(){
          if (this.otpdata) {
              this.otperror = false;
          }
        },
        errorup(){
            this.error =null;
        },
      isNumber: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
          } else {
            return true;
          }
        },
  }, 
  mounted() {
    AOS.init()
    // console.log('show',this.modelValue)
  },
  }
  </script>
   <style scoped>
   input[type="email"], input[type="password"] {
        border-radius: .5vh;
        border: 1px solid #c9c9c9;
        padding: .1rem .7rem;
    }
    template{
      font-family: "Inter", sans-serif !important;
    }
    .modal{
     position: fixed !important;
    }
    input[type="submit"], .submit1 {
        width: -moz-fit-content;
        width: fit-content;
        color: #757575;
        background: white;
        text-decoration: none;
      
        padding: .2rem 2rem;
        /* font-family: 'Lobster Two'; */
        font-size: larger;
        border-radius: 5vh;
        transition: 0.3s;
        border: 1px solid #757575;
    }

   </style>