<template>
    <body data-aos-easing="slide" data-aos-duration="800" data-aos-delay="0" class="responsivewhite">
          <div class="d-flex flex-wrap">
              <SideNav :active="active" :show="show" />
              <div class="col-lg-9 col-xl-10 col-12 p-0">
                  <div class="d-flex flex-wrap ">
                      <MainNav  :active="active"  :show="show"  @nav-show="sidenav" />
                      <Spinner v-if="loading" />
                      <div v-else class="col-12 p-0">
                        <main class="p-0 p-lg-2 pt-5 mt-4 mt-lg-0 pt-lg-0">
                            <div class="mainbody2 d-flex flex-wrap p-3">
                                <div class="col-lg-4 col-xl-4 col-12 pe-lg-3 pb-3 pb-lg-0">
                                    <CarouselSlider  :images="images" :base_url="base_url"/>
                                    <br>
                                    <UserDetails :data="data" :imgsRef="imgsRef" :key="data"  />
                                </div>
                                <Tab :data="data" :key="data" />
                            </div>
                        </main>
                     </div>
                  </div>
              </div>
          </div>
          <MobileNav :active="active"/>
          <ErrorToast  :key="error"    :message="error"   @update="toastUp" />
   </body>
</template>
<script>
    import AOS from 'aos'
    import { Icon } from "@iconify/vue";
    import MainNav from "@/components/Authenticated/UserMainNav.vue";
    import SideNav from "@/components/Authenticated/UserSideNav.vue";
    import MobileNav from  "@/components/Authenticated/MobileNav.vue";
    import CarouselSlider from "@/components/Authenticated/Show/CarouselSlider.vue";
    import UserDetails from "@/components/Authenticated/Show/Details.vue";
    import Tab from "@/components/Authenticated/Show/Tab.vue";
    import { show } from '@/util/Services/Authenticated/userShow';
    import Spinner from "@/components/Authenticated/Spinner.vue";
    import { ref } from 'vue';
    import ErrorToast from "@/components/General/ErrorToast.vue"
    export default {
      props: ['id'],
      name: "UserHomeView",
      components: {
          MainNav,SideNav,Icon,MobileNav,CarouselSlider,UserDetails,Tab,Spinner,ErrorToast
      },
      setup() {
          const active                  = "Show";
          const show                    = ref(false);
          const loading                 = ref(false);
          const images                  = ref([]);
          const data                    = ref({});
          const base_url                = ref("");
          const error                   = ref(null);
          const imgsRef                 = ref([]);
          return {
            active,show,images,data,base_url,loading,error,imgsRef
          }
      },
      methods: {
          sidenav() {
              this.show = !this.show;
          },
          async CallAPI(){
            let Arrays  = [];
              this.loading = true;
              await show(this.id).then(r=>{this.data = r?.data?.data,Arrays = r?.data?.data?.user_data?.family_images_view ? r?.data?.data?.user_data?.family_images_view : [],this.error = r?.response?.data?.message});
              if (this.error) {
                if (this.$store.getters.remaining_counting < 1) {
                  this.$router.push('/user/requests/1');
                }
              }
              let base_url = this.data?.base_url;
              this.imgsRef = Arrays.map(myFunction);
              function myFunction(num) {
                    return base_url+num?.fam_image;
              }
              
              this.slideImage(this.data);
          },
          slideImage(data){
              let base_url = data?.base_url;
              this.profile_pic = base_url+data?.user_data?.profile_pic;
              this.images = [this.profile_pic].concat(this.images);
              if (data?.user_data?.user_image.length > 0) this.images = data?.user_data?.user_image.map(lodimage);
              function lodimage(num) {
                    return  base_url+num?.image;
              }
              this.loading = false;

            //   console.log(this.images );
          },
          toastUp(){
                    this.error = null;
            },
      },
      mounted() {
        AOS.init() 
      },
      created(){
        this.CallAPI(); 
      }
    };
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped src="@/assets/css/components/Authenticated/tab_card.css"/>

   
    