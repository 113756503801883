<template>
    <div class="col-xl-3 col-lg-4 col-md-4 col-12">
                                    <div class="mainbody2 d-grid m-2">
                                        <div class="row ">
                                            <div class="editprof">
                                                <img :src="$store.getters.base_url+$store.getters.user?.profile_pic" alt="">
                                                <div class="d-grid ps-3">
                                                    <h5>{{ $store.getters.user?.name }}</h5>
                                                    <p>{{$store.getters.user?.user_ids}}</p>
                                                    <a  :href="link">
                                                        <span v-if="links">User Edit</span> <span v-else >Image Edit</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row col-12">
                                            <div class="col-lg-12">
                                                <div class="profileheading p-4 d-flex text-center">
                                                    <iconify-icon
                                                        icon="material-symbols-light:workspace-premium-outline"></iconify-icon>
                                                    <h5 class="text-center">Your are {{ data?.membership ? data?.membership:'Gold Member' }}</h5>
    
                                                </div>
                                                <div class="p-4 pt-0 ">
                                                    <h6>Profile Completion</h6>
                                                    <div class="progress ">
    
                                                        <div class="progress-bar " role="progressbar"
                                                            :aria-valuenow="percentage" aria-valuemin="0" :style="pre" aria-valuemax="100"> {{percentage}}%
                                                        </div>
                                                        <span v-if="pending" class="tooltiptext ">{{ pending }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-4 pt-0 ">
                                            <div class="col-md-12 py-2 col-sm-6" v-for="item in JourneyData">
                                                <lookbackCard :item="item" />
                                            </div>
                                          
                                        </div>
                                        <hr class="my-2 d-block d-lg-none">
                                        <div class="d-flex  justify-content-center mb-5">
                                            <button @click="LogOut" class="submitbtn">Logout</button>
                                        </div>
                                        
                                    </div>
                                </div>
</template>
<script>
  import AOS from 'aos'
  import { Icon } from "@iconify/vue";
  import {home} from "@/util/Services/Authenticated/home"
  import lookbackCard from "@/components/Authenticated/Home/LookbackCard.vue"
  import { logout } from '@/util/Services/Authenticated/Logout';
import { required } from '@vuelidate/validators';
  export default {
    components: {
      Icon,lookbackCard
    },
    props: {
        percentage: { required: true },
        pending_data: { required: true },
        links:{ required: true}
  },
  computed: {
      pre () {
        return 'width: ' + this.percentage+'% ; background-color: #35CB00;' ;
      },
      pending () {
        return this.pending_data ? 'Pending: ' + this.pending_data : null;
      },
    },
  methods:{
    async ApiCall(){
        await home().then(r=>{this.data = r.data});
        this.AddData();
    },
   async LogOut(){
       await logout().then(r=>{this.error = r?.response?.data?.message})
        if (!this.error) {
            this.$router.push({path: '/home'});
        }
      },
    AddData(){
        this.JourneyData = [
            {
                icon:'fluent:link-person-24-regular',
                number:this.data?.match_your_preference,
                head:"New Members",
                p:"Match your Preference",
                a:"/user/matches/1"
            },
            {
                icon:'fluent:person-sync-32-regular',
                number:this.data?.viewed_your_profile,
                head:"Members",
                p:"Viewed Your Profile",
                a:"/user/matches/5"
            },
            {
                icon:'fluent:person-star-28-regular',
                number:this.data?.accepted_inerest,
                head:"Members",
                p:"Accepted your interest",
                a:"/user/accepted-proposals"
            },
        ];
    }
  },
  data(){
    return{
        JourneyData:[
            {
                icon:'fluent:link-person-24-regular',
                number:this.data?.match_your_preference,
                head:"New Members",
                p:"Match your Preference"
            },
            {
                icon:'fluent:person-sync-32-regular',
                number:this.data?.viewed_your_profile,
                head:"Members",
                p:"Viewed Your Profile"
            },
            {
                icon:'fluent:person-star-28-regular',
                number:this.data?.accepted_inerest,
                head:"Members",
                p:"Accepted your interest"
            },
        ],
        per:'width:'+this.percentage+'%',
        link:'/user/profile_image'
    }
  },
    mounted() {
      AOS.init();
      this.ApiCall();
      if (this.links == true) {
        this.link = '/user/profile_edit/0';
      }
    },
  };
</script>
<style scoped src="@/assets/css/application.css" />
<style scoped>
.submitbtn{
    max-width: 250px;
}
.tooltip {
  position: relative;
  display: inline-block;
  
}

.progress .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  margin-top: 25px;
  margin-right: 15px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  padding: 10px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.progress:hover .tooltiptext {
  visibility: visible;
}

</style>
