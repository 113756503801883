<template>
<div class="row ">
        <div class="col-5 pr"> <p>{{ item?.head }}</p> </div>
        <div class="col-1 pr"> <p> :</p> </div>
        <div class="col-6 p-0"> <b v-bind:class="{'blur-1':item?.lock}" >{{ item?.value }}</b></div>
</div>
</template>
<style scoped src="@/assets/css/application.css" ></style>
<script>
export default {
    props: {
    item: {
      required: true,
    }
  }
     
    }
</script>
<style scoped>
p, b {
    font-size: .85rem;
    
}
.pr{
    padding-right: 4px !important;
}
</style>